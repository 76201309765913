import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import ActivityIndicator from './activity-indicator';

import ControlSelectDiseases from './control-select-diseases';
import empty_color_icon from '../assets/production/images/empty-color-icon.png'

import diseaseApi from '../api/disease-api';

import { SketchPicker } from 'react-color'

class ModalDiseaseSettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disease_color_settings: [
                {
                    disease_id: null,
                    color: null,
                },
            ],
            static_disease_list: [],
            statis_umls_disease_list: [],
            disease_options: [],
            other_options: [],
            displayColorPicker: {
                status: false,
                index: null
            },
        }

        this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
        this.handleClickPopOver = this.handleClickPopOver.bind(this);
        this.handleClosePopOver = this.handleClosePopOver.bind(this);
        this.handleAddDiseaseSetting = this.handleAddDiseaseSetting.bind(this);
        this.handleRemoveDiseaseSetting = this.handleRemoveDiseaseSetting.bind(this);
        this.buildDiseaseChoices = this.buildDiseaseChoices.bind(this);
        this.handleDiseaseDropdownChange = this.handleDiseaseDropdownChange.bind(this);
        this.getDiseaseFieldValue = this.getDiseaseFieldValue.bind(this);
        this.isDiseaseFromUmls = this.isDiseaseFromUmls.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async componentDidMount() {

        this.setState({ loading: true });

        try{

            let data = await diseaseApi.get_disease();
            let umls = await diseaseApi.get_umls_diseases(this.props.user.clinician_id)
            let diseases = data.diseases.sort((a, b) => {
                if (a.disease_name < b.disease_name) return -1;
                if (a.disease_name > b.disease_name) return 1;
                return 0;
            });

            const uniqueUMLS = Array.from(new Set(umls.umls_diseases.map(a=>a.umls_name))).map(name=>{
                return umls.umls_diseases.find(a =>a.umls_name === name)
              })

            let umls_diseases = uniqueUMLS.sort((a, b) => {
                if (a.umls_name < b.umls_name) return -1;
                if (a.umls_name > b.umls_name) return 1;
                return 0;
            })

            await this.buildDiseaseChoices(diseases, umls_diseases);

            let disease_color_settings = this.props.getPedigreeData().getDiseaseColorSettings();
            if (disease_color_settings.length == 0) {
                disease_color_settings = [
                    {
                        disease_id: null,
                        color: null,
                    }
                ]
            }

            this.setState({
                disease_color_settings: disease_color_settings,
                static_disease_list: diseases,
                statis_umls_disease_list: umls_diseases
            });

        }
        catch(e){
            console.log(e)
        }
        finally{
            this.setState({ loading: false });
        }


        // this.setState({ diseases: this.props.diseases })
    }

    onChange(field, value) {
        // this.setState({ [field]: value })
    }

    async onClickSave() {
        try{
            this.setState({ loading: true });

            let disease_color_settings_copy = JSON.parse(JSON.stringify(this.state.disease_color_settings))

            let payload = {
                disease_color_settings: disease_color_settings_copy,
                type: 'organization'
            }

            if(disease_color_settings_copy.length == 0 || (disease_color_settings_copy.length == 1 && (disease_color_settings_copy[0].color == null || disease_color_settings_copy[0].disease_id == null))){
                payload = {
                    disease_color_settings: [],
                    type: 'organization'
                }
            }

            await diseaseApi.save_disease_color_settings(payload)
            this.props.getPedigreeData().setDiseaseColorSettings(payload.disease_color_settings)
            await this.props.onSave();
        }
        catch(e){
            console.log(e)
        }
        finally{
            this.setState({ loading: false });
        }
    }

    handleAddDiseaseSetting(){
        let disease_color_settings = JSON.parse(JSON.stringify(this.state.disease_color_settings));
        disease_color_settings.push({
            disease_id: null,
            color: null,
        });
        this.setState({ disease_color_settings: disease_color_settings });
    }

    handleRemoveDiseaseSetting(index){
        let disease_color_settings = JSON.parse(JSON.stringify(this.state.disease_color_settings));

        if (index > -1) {
            disease_color_settings.splice(index, 1);
        }

        if (disease_color_settings.length == 0) {
            disease_color_settings.push({
                disease_id: null,
                color: null,
            });
        }

        this.setState({ disease_color_settings: disease_color_settings });
    }

    handleColorPickerChange(color, index, event) {
        let disease_color_settings = JSON.parse(JSON.stringify(this.state.disease_color_settings));
        disease_color_settings[index].color = color;
        this.setState({ disease_color_settings: disease_color_settings });
    }

    handleClickPopOver(index) {
        if (!this.state.displayColorPicker.status == false) {
            this.setState({
                displayColorPicker: {
                    status: false,
                    index: null
                }
            });
        }
        else {
            this.setState({
                displayColorPicker: {
                    status: true,
                    index: index
                }
            });
        }

    };

    handleClosePopOver() {
        this.setState({
            displayColorPicker: {
                status: false,
                index: null
            }
        });
    };

    handleDiseaseDropdownChange(index, disease) {
        let disease_color_settings = JSON.parse(JSON.stringify(this.state.disease_color_settings));
        disease_color_settings[index].disease_id = disease.value;
        this.setState({ disease_color_settings: disease_color_settings });
    }

    async buildDiseaseChoices(static_diseases, static_umls_diseases) {
        // Arrange by group
        let diseases = static_diseases;
        let umlsOptions = []
        let umls_options = static_umls_diseases;

        let diseases_by_category = {};
        for (var disease of diseases) {
          if (disease.disease_type in diseases_by_category) {
            diseases_by_category[disease.disease_type].push(disease);
          } else {
            diseases_by_category[disease.disease_type] = [disease];
          }
        }

        //Parse and Format
        let options = []; //[{ value: '', label: ' ' }]
        let other_options = [];
        let first_options = [
        //   {value: 'any_disease', label: 'Any Disease'},
        //   {value: 'no_disease', label: 'No Disease'},
        ];

        options.push({
          label: '',
          options: first_options
        });

        other_options = other_options.concat(first_options);

        let all_diseases_by_category = [];

        for (var category_name in diseases_by_category) {
          let fmt_category_name = category_name.replace("-", " ").replace("_", " ").toUpperCase()
          if(fmt_category_name == 'CANCER POLYPS') fmt_category_name = "CANCER/POLYPS"

          let extra_options = [
            {value: 'any_'+category_name, label: 'Any ' + fmt_category_name + ' Condition'},
            // {value: 'no_'+category_name, label: 'No ' + fmt_category_name + ' Condition'}
          ];

          other_options = other_options.concat(extra_options);

          const cat_options = diseases_by_category[category_name].map(item => ({
            value: item.disease_code ? item.disease_code : item.umls_id,
            label: item.disease_name
          }));

          //collect all unique diseases of all probands
          all_diseases_by_category = all_diseases_by_category.concat(diseases_by_category[category_name])

          extra_options = extra_options.concat(cat_options);
          options.push({
            label: fmt_category_name,
            options: extra_options
          });
        }

        //Sort the categories
        options = options.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

        let intial_umls = [
          {value: 'any_UMLS', label: 'Any UMLS disease'},
        //   {value: 'no_UMLS', label: 'No UMLS disease'}
        ]

        other_options =  other_options.concat(intial_umls);

        intial_umls.forEach(umls =>{
          umlsOptions.push({
            value: umls.value ,
            label: umls.label
          })
        })

        for(var umls of umls_options){
          if( umls.umls_id !== null && umls.umls_id !== undefined) {
            umlsOptions.push({
              value: umls.umls_id,
              label: umls.umls_name
            });
          }
        }

        //filter out the other diseases on the umls to avoid duplicates
        umlsOptions = umlsOptions.filter((umls) => !all_diseases_by_category.find((disease) => umls.value === disease.id));

        if (umls_options.length > 0) {
          options.push({
            label: "UMLS",
            options: umlsOptions
          });
        }

        // Add empty option
        // options = [{ value: "", label: " " }].concat(options);

        for(let opti of options){
          if(opti.label == 'OTHER CONDITIONS'){
            opti.options = opti.options.filter(opt => opt.value != 'any_other_conditions' && opt.value != 'no_other_conditions')
          }
        }

        if (options.length > 1) {
          this.setState({ disease_options: options, other_options: other_options });
        }
    }

    isDiseaseFromUmls(disease_id) {
        disease_id = disease_id + "";
        const is_from_umls = disease_id.substr(0,1) === "C";
        return is_from_umls
    }

    getDiseaseFieldValue(disease_id) {
        let disease = null;
        let static_selected_disease = this.state.static_disease_list.find(
            item => item.disease_code === disease_id || item.umls_id === disease_id
        );

        if (static_selected_disease) {
            disease = { value: static_selected_disease.id, label: static_selected_disease.disease_name };
        }
        else {
            static_selected_disease = null;
            const is_from_umls = this.isDiseaseFromUmls(disease_id);
            let umls_id = disease_id
            if (is_from_umls) {
                let static_selected_disease = this.state.statis_umls_disease_list.find(
                    item => item.umls_id === umls_id
                );
                if (static_selected_disease) {
                    disease = { value: static_selected_disease.umls, label: static_selected_disease.umls_name };
                }
            }
        }
        if (disease === null) {
            for (let option of this.state.other_options) {
                if (option.value === disease_id) {
                    disease = option;
                }
            }
        }
        return disease;
    }

    closeModal() {
        this.props.onCancel();
    }


    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        };
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        };

        return ReactDOM.createPortal(
            <div
                style={{ display: 'block' }}
                className="modal fade in"
                role="dialog">

                <ActivityIndicator loading={this.state.loading} modal={true} />

                <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button
                                onClick={() => this.closeModal()}
                                type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-close"></i>
                            </button>
                            <h4 className="modal-title text-white text-capitalize">Disease Settings</h4>
                        </div>

                        <div className="modal-body">

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {this.state.disease_color_settings.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: '50%', // Each component will take up half the width
                                            padding: '10px',
                                            boxSizing: 'border-box',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <span>
                                                <span onClick={() => this.handleClickPopOver(index)}>
                                                    {item.color ?
                                                        <div style={{ height: 34, width: 29, backgroundColor: item.color.hex }}></div>
                                                        :
                                                        <img height='34' width='34' src={empty_color_icon}>
                                                        </img>
                                                    }
                                                </span>
                                                {this.state.displayColorPicker.status && this.state.displayColorPicker.index == index ? <div style={popover}>
                                                    <div style={cover} onClick={this.handleClosePopOver} />
                                                    <SketchPicker disableAlpha={true} color={item.color ? item.color.rgb : {}} onChange={(color, event) => this.handleColorPickerChange(color, index, event)}/>
                                                </div> : null}
                                            </span>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                {/* disease list */}
                                                <div style={{textAlign: 'left'}}>
                                                    <ControlSelectDiseases
                                                        options={this.state.disease_options}
                                                        onChange={(disease) => this.handleDiseaseDropdownChange(index, disease)}
                                                        value={this.getDiseaseFieldValue(item.disease_id)}
                                                        placeholder=""
                                                        autofocus={false}
                                                    />
                                                </div>
                                            </div>
                                            {((this.state.disease_color_settings.length > 1) || (this.state.disease_color_settings[0].color != null && this.state.disease_color_settings[0].disease_id != null)) &&
                                                <a onClick={() => this.handleRemoveDiseaseSetting(index)}>
                                                    <i className="fa fa-trash"></i>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>

                        <div className="margin-two">
                            <a onClick={() => this.handleAddDiseaseSetting()} className="btn btn-teal btn-xs"><i className="fa fa-plus"></i> Add</a>
                        </div>

                        </div>

                        <div className="modal-footer">
                            <button
                                onClick={() => this.closeModal()}
                                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
                            <button
                                onClick={() => this.onClickSave()}
                                type="button" className="btn btn-dark">Save</button>
                        </div>
                    </div>
                </div>
            </div>,
            document.body
        );
    }

}

export default ModalDiseaseSettings
