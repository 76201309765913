import React, { Component } from 'react';

import familyApi from '../../api/family-api';

class RelationshipDropdownMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consanguineous: false,
            relationship_data: ''
        };

        this.onOptionClick = this.onOptionClick.bind(this)
    }

    componentDidMount(){
        let relationship_rkey = this.props.data.id.slice(2).slice(0, -2)

        //need to get the actual relationship_data
        let people = Object.values(this.props.data.getPedigreeData().profiles)
        let peopleWithRelationshipRkey = people.filter(person => person.relationship_ids.find(relationship => relationship.rkey == relationship_rkey))
        let relationship_data = JSON.parse(JSON.stringify(peopleWithRelationshipRkey[0].relationship_ids.find(relationship => relationship.rkey == relationship_rkey && 'is_manually_connected' in relationship)))

        this.setState({ relationship_data })
    }

    async onOptionClick(option){
        try{
            let relationship_data = this.state.relationship_data;

            let relationship_rkey = relationship_data.rkey
            let people = Object.values(this.props.data.getPedigreeData().profiles)
            let peopleWithRelationshipRkey = people.filter(person => person.relationship_ids.find(relationship => relationship.rkey == relationship_rkey))

            if(option == 'remove'){
                let delete_payload = {
                    partner_id: relationship_data.id
                }
                await familyApi.delete_partner_relationship_partnerid(delete_payload)

                for(let person of peopleWithRelationshipRkey){
                    person.relationship_ids = person.relationship_ids.filter(relationship => relationship.id != relationship_data.id)

                    this.props.data.getPedigreeData().setProfile(person.rkey, person)
                }

                await this.props.data.reRenderPedigree();
                return;
            }

            if(option !== 'consanguineous'){
                relationship_data.marital_status = option;
            }
            else{
                relationship_data.is_parent_blood_related = !relationship_data.is_parent_blood_related;
            }
            await familyApi.patch_partner_relationship_partnerid(relationship_data.id, relationship_data)

            //update the relationship_data on pedigree data store
            for(let person of peopleWithRelationshipRkey){
                person.relationship_data = JSON.parse(JSON.stringify(relationship_data))
                let relationship_ids = JSON.parse(JSON.stringify(person.relationship_ids))

                for(let relation of relationship_ids){
                    if(relation.rkey === relationship_rkey){
                        if(option !== 'consanguineous'){
                            relation.marital_status = relationship_data.marital_status
                        }
                        else{
                            relation.is_parent_blood_related = relationship_data.is_parent_blood_related;
                            // if(!person.is_proband){
                            //     if(relationship_data.is_parent_blood_related === true){
                            //         person.is_blood_related_to_proband = true
                            //     }
                            //     else{

                            //     }
                            //     await familyApi.patch_member_memberid(person.id, person)
                            // }
                        }
                    }
                }

                person.relationship_ids = JSON.parse(JSON.stringify(relationship_ids))

                this.props.data.getPedigreeData().setProfile(person.rkey, person)
            }

            await this.props.data.reRenderPedigree();
        } catch(e){
            console.log(e.stack);
        }
    }

    render() {
        const show_dropdown = this.props.showDropdown ? 'open' : '';

        const dropdown_menu_styles = {
            fontFamily: 'sans-serif',
            fontSize: 12,
            backgroundColor: '#ffffff'
        };

        const dropdown_label = Object.assign({}, dropdown_menu_styles, { fontWeight: 'bold', textAlign: 'justify', padding: '15px 20px', whiteSpace: 'nowrap' });

        let people = Object.values(this.props.data.getPedigreeData().profiles)
        const children = people.filter(person => (person.father_id == this.state.relationship_data.father_id && person.mother_id == this.state.relationship_data.mother_id) || (person.father_id == this.state.relationship_data.mother_id && person.mother_id == this.state.relationship_data.father_id))

        return (
            <div className={"dropdown " + show_dropdown} style={{ left: '5px' }}>

                <ul className="dropdown-menu" aria-labelledby="dropdown_children_dom">
                    <li style={dropdown_label}>Edit Relationship</li>

                    <li onClick={() => this.onOptionClick('married')} style={dropdown_menu_styles}>
                        <a>Married</a>
                    </li>

                    <li onClick={() => this.onOptionClick('separated')} style={dropdown_menu_styles}>
                        <a>Separated</a>
                    </li>

                    <li onClick={() => this.onOptionClick('divorced')} style={dropdown_menu_styles}>
                        <a>Divorced</a>
                    </li>

                    <li onClick={() => this.onOptionClick('other')} style={dropdown_menu_styles}>
                        <a>Other</a>
                    </li>

                    <li onClick={() => this.onOptionClick('consanguineous')} style={dropdown_menu_styles}>
                        <a>{!this.state.relationship_data.is_parent_blood_related ? 'Consanguineous' : 'Non-Consanguineous'}</a>
                    </li>

                    {this.state.relationship_data.is_manually_connected && children.length == 0 &&
                        <li onClick={() => this.onOptionClick('remove')} style={dropdown_menu_styles}>
                            <a>Remove</a>
                        </li>
                    }

                </ul>

            </div>)
    }
}

export default RelationshipDropdownMenu;
