import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class RiskBarChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 400,
          type: 'bar',
          id: this.props.chartId,
          toolbar: {
            show: true
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          markers: {
            fillColors: ['#008ffb', '#00e396']
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            top: 20,
            right: 20,
            left: 20,
            bottom: 20
          }
        },
        labels: this.props.labels,
        xaxis: {
          title: {
            text: 'Risk Category',
            style: {fontSize: '14px'}
          }
        },
        yaxis: {
          title: {
            text: 'Cancer Risk %',
            style: {fontSize: '14px'}
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            distributed: true
          }
        }
      },
      tooltip: {
        marker: {
          fillColors: []
        }
      },
      series: this.props.payload,
    };
  }

  hideToolbarForPrint() {
    this.setState({ options: { chart: { toolbar: { show: false } } } });
  }

  hideLegendForPrint() {
    this.setState({ options: { legend: { show: false } } });
  }

  render() {
    return (
      <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="400" />
    );
  }
}

export default RiskBarChart;