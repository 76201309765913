import React, { useState } from 'react';
import Select from 'react-select';

interface printDropdownsProps {
  options: any;
  selectedValue: any;
  onSelect: any;
  disabled?: boolean;
  label?: string;
}

const PrintDropdowns: React.FC<printDropdownsProps> = ({ options, selectedValue, disabled, onSelect, label }) => {
  const [selected, setSelected] = useState(selectedValue);

  const handleSelect = (value: { label: string; value: string }) => {
    setSelected(value);
    onSelect(value);
  };

  return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h5 className='popover-header popover-title"'style={{ width: '100px' }}>{label}</h5>
            <Select
              classNamePrefix="custom-react-select"
              className="react-select-container gene-dropdown"
              value={selected} 
              onChange={handleSelect}
              options={options}
              isDisabled={disabled}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '55%'
                })
              }}
            />
        </div>
      </>
    )
};

export default PrintDropdowns;
