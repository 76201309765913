import React, { Component } from 'react';
import settings from '../configs/settings'
import helpers from '../helpers/index';
import NavTop from '../components/nav-top';
import { Link } from 'react-router-dom';
import ActivityIndicator from '../components/activity-indicator'
import AccountTabDisplay from '../components/accounts/account-tab-display';
import ModalEditSubAccount from '../components/sub-accounts/modal-edit-sub-account';
import { cloneDeep } from 'lodash';
import account_api from '../api/user-account-api';
import route_helper from '../route-helper';
import { radix_bases } from '../helpers/helper-number-bases';
import file_api from '../api/file-api';
import axios from 'axios';

const static_org = {
  id: null,
  org_name: null,
  org_type: null,
  units: null,
  dial_code: null,
  default_partner_view: null,
  password_reset_timeframe: null,
  auto_logout_time: null,
  logo: null,
  clinician_id: null,
  parent_org: null,
  license_id: null,
  is_locked: false,
  show_gender_identity: false,
  tc_lifetime_age: 85,

  license: {
    id: null,
    boadicea: false,
    tyrer_cuzick: false,
    gail: false,
    claus: false,
    brcapro: false,
    mmrpro: false,
    pancpro: false,
    melapro: false
  },
  contract_start_date: '',
  renewal_date: '',
  specialty: '',
  key_contacts: '',
  contract_summary: '',
  notes: '',
  client_type: '',
  customizations: {
    disease_list: false,
    surveys: false,
  },
  integration_api: {
    auto_invite: false,
    emr: false,
    xml_export: false,
    sso: false,
    other: false,
    other_val_string: ''
  },
  white_label_options: {
    terms_and_conditions: false,
    support_web_page: false,
    automatic_opt_in_patients: false,
    automatic_opt_in_family_members: false,
    hide_clinicians: false,
    hide_risk: false,
    genetic_resources: false,
  },
  clinician_code: {
    id: null,
    code: null
  },
  parent_organization: null,
};

function parseToInt(val) {
  return parseInt(val, radix_bases.base10);
}

class SubAccountPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      org: static_org,
      showEditSubOrgModal: false,
      loading: false,
      surveys: [],
      survey_display: [],
      survey_options: []
    };
    this.toggleEditSubOrgModal = this.toggleEditSubOrgModal.bind(this);
    this.onSaveSubOrg = this.onSaveSubOrg.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.saveLogoUrl = this.saveLogoUrl.bind(this);
    this.routeToOrgPage = this.routeToOrgPage.bind(this);
  }

  async componentDidMount() {
    helpers.setSiteTitle('Sub Account');

    const org_id = this.props.match.params.org_id;
    if(parseToInt(org_id)) {
      this.setState({loading: true});

      try {
        let survey_options = await account_api.get_surveys();
        survey_options = survey_options.map((survey) => {
          return {value: survey.id, label: survey.name}
        });
        // calling by iteself because it wasn't working when combined with other state
        this.setState({survey_options: survey_options});

        const org_data = await account_api.get_org_by_id(org_id);
        const tc_lifetime_age = await account_api.get_tc_lifetime_age({"org_id": org_id, "clinician_id": org_data.clinician_code.id});

        let parent_org_data = null;
        if(parseToInt(org_data.org.parent_org)) {
          parent_org_data = await account_api.get_org_by_id(org_data.org.parent_org);
        }

        const org_surveys = await account_api.get_org_surveys(org_data.clinician_code.id);

        const survey_names = []
        for (let i = 0; i < org_surveys.length; i++) {
          survey_names.push(org_surveys[i].survey_id.name)
          if (i < org_surveys.length-1) {
            survey_names.push(', ')
          }
        }

        const prev_surveys = []
        for (let i=0; i < org_surveys.length; i++) {
          for (let j=0; j < this.state.survey_options.length; j++) {
            if (org_surveys[i].survey_id.id == this.state.survey_options[j].value) {
              prev_surveys.push(this.state.survey_options[j]);
            }
          }
        }

        this.setState({
          org: {
            ...org_data.org,
            license: org_data.license,
            clinician_code: org_data.clinician_code,
            parent_organization: parent_org_data,
            customizations: org_data.customization,
            integration_api: org_data.integration_api,
            white_label_options: org_data.white_label_option,
            tc_lifetime_age: tc_lifetime_age
          },
          survey_display: survey_names,
          surveys: prev_surveys
        });

      } catch (error) {
        console.log(error);
      } finally {
        this.setState({loading: false});
      }

    }
  }

  setOrgLicenseState(payload){
    this.setState({
      org: {
        ...this.state.org,
        contract_start_date: payload.contract_start_date,
        renewal_date: payload.renewal_date,
        specialty: payload.specialty,
        key_contacts: payload.key_contacts,
        contract_summary: payload.contract_summary,
        notes: payload.notes,
        client_type: payload.client_type,
        customizations: payload.customizations,
        integration_api: {
          auto_invite: payload.integration_api.auto_invite,
          emr: payload.integration_api.emr,
          xml_export: payload.integration_api.xml_export,
          sso: payload.integration_api.sso,
          other: payload.integration_api.other,
          other_val_string: payload.integration_api.other_val
        },
        white_label_options: payload.white_label_options,
        tc_lifetime_age: payload.tc_lifetime_age,
        license: {
          ...this.state.org.license,
          boadicea: payload.risk_models.boadicea,
          tyrer_cuzick: payload.risk_models.tyrer_cuzick,
          gail: payload.risk_models.gail,
          claus: payload.risk_models.claus,
          brcapro: payload.risk_models.brcapro,
          mmrpro: payload.risk_models.mmrpro,
          pancpro: payload.risk_models.pancpro,
          melapro: payload.risk_models.melapro
        },
      }
    })
  }

  toggleEditSubOrgModal(event) {
    this.setState({showEditSubOrgModal: !this.state.showEditSubOrgModal});
  }

  async routeToOrgPage(org_type, org_id) {
    // Redirect to super Page if now a super/standard org
    if(org_type !== settings.app_constants.subOrg.value) {
      this.props.history.push(route_helper.administrative.fh_super_account.replace(':org_id', org_id));
    }
  }

  async onSaveSubOrg(org) {
    let cloned = cloneDeep(org);
    const { license, clinician_code } = cloned;
    delete cloned.license;
    delete cloned.clinician_code;

    const new_logo = cloned.newLogo;
    delete cloned.newLogo

    const surveys = cloned.surveys;
    delete cloned.surveys;

    //check if max number of pedigrees is empty, if yes, set to null
    if(!(/\S/.test(cloned.max_number_of_pedigrees))){
      cloned.max_number_of_pedigrees = null;
    }

    // send saved data to the server
    const json = {
      org_name: cloned.org_name,
      org_type: cloned.org_type,
      units: cloned.units,
      dial_code: cloned.dial_code,
      password_reset_timeframe: cloned.password_reset_timeframe,
      auto_logout_time: cloned.auto_logout_time,
      logo: cloned.logo,
      is_locked: cloned.is_locked,
      parent_org: cloned.parent_org ? cloned.parent_org : null,
      show_gender_identity: cloned.show_gender_identity,
      race_ethnicity_display: cloned.race_ethnicity_display,
      risk_model_years: cloned.risk_model_years,
      number_of_licenses: cloned.number_of_licenses,
      max_number_of_pedigrees: cloned.max_number_of_pedigrees,
      default_partner_view: cloned.default_partner_view,
      nice_categories: cloned.nice_categories,
    };
    const org_data = await account_api.patch_organization(this.state.org.id, json);

    const code = (clinician_code) ? clinician_code.code : null;
    let clinician_code_data = null;
    if(clinician_code && clinician_code.id && code) {
      clinician_code_data = await account_api.patch_clinician_code(clinician_code.id, {code: code});
    }

    let license_data = null;
    if(license && license.id) {
      license_data = await account_api.patch_license(license.id, {
        boadicea: license.boadicea,
        tyrer_cuzick: license.tyrer_cuzick,
        gail: license.gail,
        claus: license.claus,
        brcapro: license.brcapro,
        mmrpro: license.mmrpro,
        pancpro: license.pancpro,
        melapro: license.melapro,
        qrisk3: license.qrisk3,
      });
    }

    const survey_ids = surveys.map((survey) => {
      return survey.value
    });

    const survey_orgs = await account_api.save_org_surveys(clinician_code.id, survey_ids);

    const org_surveys = await account_api.get_org_surveys(clinician_code.id);

    await account_api.save_tc_lifetime_age({"org_id": org.id, "lifetime_age": parseInt(org.tc_lifetime_age)});
    org_data['tc_lifetime_age'] = org.tc_lifetime_age == "" ? 85 : parseInt(org.tc_lifetime_age);

    let survey_options = await account_api.get_surveys();
    survey_options = survey_options.map((survey) => {
      return {value: survey.id, label: survey.name}
    });
    // calling by iteself because it wasn't working when combined with other state
    this.setState({survey_options: survey_options});

    const survey_names = []
    for (let i = 0; i < org_surveys.length; i++) {
      survey_names.push(org_surveys[i].survey_id.name)
      if (i < org_surveys.length-1) {
        survey_names.push(', ')
      }
    }

    const prev_surveys = []
    for (let i=0; i < org_surveys.length; i++) {
      for (let j=0; j < this.state.survey_options.length; j++) {
        if (org_surveys[i].survey_id.id == this.state.survey_options[j].value) {
          prev_surveys.push(this.state.survey_options[j]);
        }
      }
    }

    this.setState({
      org: {
        ...org_data,
        license: (license_data) ? license_data : license,
        clinician_code: (clinician_code_data) ? clinician_code_data : clinician_code
      },
      survey_display: survey_names,
      surveys: prev_surveys
    });

    if(new_logo) {
      await this.uploadFiles(new_logo, org_data);
    } else {
      this.routeToOrgPage(org_data.org_type, org_data.id);
    }
  }

  async uploadFiles(org_logo, org) {
    // Send data to ap server for presigned url
    // uplaod to S3
    // if successful save the logo url to the orgs url field
    // if unsuccessful then delete the file record that was created in the DB

    let response = await file_api.upload_org_logo({
      original_filename: org_logo.name,
      custom_filename: org_logo.name,
      mime_type: org_logo.type,
      file_size: org_logo.size,
      file_note: null,
      file_category: null
    });

    if ('data' in response) {
      const aws_s3 = response.data.s3;
      const public_url = aws_s3.url + aws_s3.fields.key;

      const formData = new FormData();
      for (const key in aws_s3.fields) {
        formData.append(key, aws_s3.fields[key]);
      }
      // formData.append("key", aws_s3.fields.key);
      // formData.append("x-amz-algorithm", aws_s3.fields['x-amz-algorithm']);
      // formData.append("x-amz-credential", aws_s3.fields['x-amz-credential']);
      // formData.append("x-amz-date", aws_s3.fields['x-amz-date']);
      // formData.append("policy", aws_s3.fields.policy);
      // formData.append("x-amz-signature", aws_s3.fields['x-amz-signature']);
      // formData.append("acl", aws_s3.fields.acl);
      formData.append("file", org_logo, org_logo.name);

      axios.post(aws_s3.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        // success
        this.saveLogoUrl(public_url, org);
      })
      .catch((error) => {
        // delete the record in s3files table in db because the post to S3 failed
        // we do not want a lingering record not pointing to an actual S3 file
        file_api.delete_user_file_from_db(response.data.file_record.id);
        this.routeToOrgPage(org.org_type, org.id);
        return null;
      });
    }
    return null;
  }

  async saveLogoUrl(public_url, org) {
    if(public_url) {
      // send saved data to the server
      const json = {
        org_name: org.org_name,
        org_type: org.org_type,
        units: org.units,
        dial_code: org.dial_code,
        password_reset_timeframe: org.password_reset_timeframe,
        auto_logout_time: org.auto_logout_time,
        logo: public_url,
        is_locked: org.is_locked,
        parent_org: org.parent_org
      };
      const org_data = await account_api.patch_organization(org.id, json);

      this.setState({
        org: {
          ...org_data,
          license: this.state.org.license,
          clinician_code: this.state.org.clinician_code
        }
      });
    }
    this.routeToOrgPage(org.org_type, org.id);
  }

  render() {
    let showEdit = '';
    if(this.props.location.state == undefined){
      showEdit = true
    } else if(this.props.location.state.fromAccount){
      showEdit = false
    }

    return (
      <React.Fragment>

        <NavTop history={this.props.history} />

        <section className="wow fadeIn section animated" style={{paddingBottom: '0px', visibility: 'visible', animationName: 'fadeIn'}}>
        	<div className="container">

        		<div className="section-header">
        			<div className="row vcenter">
        				<div className="col-md-6 col-xs-12">
        					<h2>{this.state.org.org_name}</h2>
        				</div>
        				<div className="col-md-4 col-md-offset-2 justify-right hidden"></div>
        			</div>
        		</div>

            <AccountTabDisplay
                    org={this.state.org}
                    setOrgLicenseState={(payload) => this.setOrgLicenseState(payload)}
                    showEdit={showEdit}
                    toggleEditSubOrgModal={this.toggleEditSubOrgModal}
                    survey_display={this.state.survey_display}
                    showFilter={true}
                    headerBtnCssClass="btn btn-light btn-xs"
                    org_id={this.state.org.id}
                    newSubOrg={this.newSubOrg}
                    isSuperAdmin={true}
            />

            <div className="section-footer clearfix margin-bottom-seven">
              <Link
                to={route_helper.administrative.fh_super_account.replace(':org_id', this.state.org.parent_org)}
                className="btn btn-light" >
                <i className="fa fa-arrow-left"></i>
                Back to Super-Org
              </Link>
            </div>
        	</div>
        </section>

          {this.state.showEditSubOrgModal &&
            <ModalEditSubAccount
              org={this.state.org}
              onClose={this.toggleEditSubOrgModal}
              onClickSave={(payload) => this.onSaveSubOrg(payload)}
              selected_surveys={this.state.surveys}
            />
          }

        <ActivityIndicator
          modal={true}
          loading={this.state.loading}
        />

      </React.Fragment>

    );
  }
}

export default SubAccountPage;
