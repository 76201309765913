import React, { Component } from "react";
import ReactDOM from "react-dom";
import ActivityIndicator from "./activity-indicator";
import ErrorSummary from "./error-summary";

class ModalConfirmDelete extends Component {
  render() {
    // defaults
    let confirmText = "Yes";
    let cancelText = "No";
    let message = "Are you sure you would like to delete this family member?";
    if (this.props.confirmText) { confirmText = this.props.confirmText; }
    if (this.props.cancelText) { cancelText = this.props.cancelText; }
    if (this.props.message) { message = this.props.message; }

    let styles = {
      paddingLeft: "0px",
      paddingRight: "0px"
    }
    if (confirmText !== "Yes" && cancelText !== "No") {
      styles = {
        width: 'auto'
      }
    }

    return ReactDOM.createPortal(
      <div
        onClick={() => this.props.onCancel()}
        style={{display: this.props.isOpen ? "block" : "none" }}
        className={"modal fade " + (this.props.isOpen ? "in" : "")}
        role="dialog"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="modal-dialog modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onCancel()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {this.props.title ? this.props.title : "Delete Details"}
              </h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">
                <ErrorSummary errorMessages={this.props.errorMessages} />

                <p>
                  {message}
                </p>

                <ActivityIndicator loading={this.props.loading} />
                <br />

                <div className="buttons">
                  <button
                    style={styles}
                    disabled={this.props.loading}
                    onClick={() => this.props.onOk()}
                    className="btn btn-purple btn-sm"
                  >
                    {confirmText}
                  </button>
                  <button
                    style={styles}
                    disabled={this.props.loading}
                    onClick={() => this.props.onCancel()}
                    className="btn btn-light btn-sm"
                    aria-label="Close"
                  >
                    {cancelText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default ModalConfirmDelete;
