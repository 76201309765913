import React, { Component } from 'react';
import { connect } from "react-redux";
import ModalRiskMissingData from './risk-assessment-tabs/modal-risk-missing-data';
import ModalRiskSettings from './risk-assessment-tabs/modal-risk-settings';
import RiskAssessmentCharts from './risk-assessment-tabs/risk-assessment-charts';
import * as patient_actions from '../../store/patient/actions';
import settings from '../../configs/settings';
import lodash, { has, isEqual, isInteger, isString } from 'lodash';
import helper_risk from '../../helpers/helper-risk';
import ActivityIndicator from '../activity-indicator';
import { cloneDeep } from 'lodash';
import * as helper_family_tree from '../../helpers/helper-family-tree';
import * as helper_health_history from '../../helpers/helper-health-history';
import family_api from "../../api/family-api";
import disease_api from "../../api/disease-api";
import risk_api from '../../api/risk-api';
import account_api from '../../api/user-account-api';
import sdk from "../../api/sdk";
import helpers from "../../helpers/index";
import helpers_risk_model_print from "../../helpers/helper-risk-model-print";
import { risk_factors as default_risk_factors } from '../../store/patient/default';
import moment, { version } from "moment";
import { radix_bases } from '../../helpers/helper-number-bases';
import helper_survey_api from "../../helpers/helper-survey-api";
import rf_constants from '../../configs/rf_constants';
import ModalPopUpBlockerAlert from '../modal-popup-blocker-alert';
import './print-fonts/arial-normal'
import './print-fonts/sans-serif-normal'
import { createUUID } from '../react-flow-pedigree/utils';

import jsPDF from 'jspdf';
import "jspdf/dist/polyfills.es.js";

import html2canvas from 'html2canvas'

import {
  ageOptions,
  stringToAge,
  ageStringToAgeFloat,
  normalizeAgeString,
  ageStringToYOB } from '../../helpers/age-utils'

const BDAY_REGEX = /^[1-2]{1}[0-9]{3}[-][0-1]{1}[0-9]{1}[-][0-3]{1}[0-9]{1}$/g;

var keys = {37: 1, 38: 1, 39: 1, 40: 1};
var wheelOpt = null;
var wheelEvent = null;
var supportsPassive = null;
const BoadiceaDiseaseIds = [2, 9, 10, 11]
const BoadiceaDiseaseCodes = ['D002',"D009", "D010","D011"]
const clausDiseaseCodes = ['D002']
const clausDiseaseIds = [ 2 ]

// TODO: refactor this component, use helper methods for doing all the risk algorithm checks etc.
class RiskAssessmentTabs extends Component {
  constructor(props) {
    super(props);
    this.riskAssessmentChartsRef = React.createRef();
    this.state = {
      orgLicense: {},
      showMissingDataModal: false,
      showRiskSettingsModal: false,
      runningRiskCalculation: false,
      runningBoadicea: false,
      runningClaus: false,
      runningGail: false,
      runningTyrerCuzick: false,
      runningBRCAPRO: false,
      runningMELAPRO: false,
      runningPANCPRO: false,
      runningMMRPRO: false,
      runningQRISK3: false,
      showRiskAssessmentCharts: true,
      showRiskTables: true,
      risk_errors: {
        requiredData: [],
        fatalTerminations: {
          boadicea: { terminate: false, terminations: [] },
          claus: { terminate: false, terminations: [] },
          gail: { terminate: false, terminations: [] },
          tyrer_cuzick: { terminate: false, terminations: [] },
          premm: { terminate: false, terminations: [] },
          brcapro: { terminate: false, terminations: [] },
          mmrpro: { terminate: false, terminations: [] },
          pancpro: { terminate: false, terminations: [] },
          melapro: { terminate: false, terminations: [] },
          qrisk3: { terminate: false, terminations: [] }
        } // all fatal terminations pertain to the proband at this time
      },
      boadiceaRuntimeErrors: [],
      clausRuntimeErrors: [],
      gailRuntimeErrors: [],
      tyrerCuzickRuntimeErrors: [],
      brcaproRuntimeErrors: [],
      mmrproRuntimeErrors: [],
      pancproRuntimeErrors: [],
      melaproRuntimeErrors: [],
      qrisk3RuntimeErrors: [],
      showNoRiskResultsModal: false,
      boadiceaRuntimeWarnings: [],
      brcaproRuntimeWarnings: [],
      mmrproRuntimeWarnings: [],
      pancproRuntimeWarnings: [],
      melaproRuntimeWarnings: [],
      qrisk3RuntimeWarnings: []
    };

    this.riskFactorsCallback = this.riskFactorsCallback.bind(this);
    this.probandHasChildren = this.probandHasChildren.bind(this);
    this.openRiskSettingsModal = this.openRiskSettingsModal.bind(this);
    this.closeMissingDataModal = this.closeMissingDataModal.bind(this);
    this.closeRiskSettingsModal = this.closeRiskSettingsModal.bind(this);

    this.onSaveMissingData = this.onSaveMissingData.bind(this);
    this.onSaveRiskSettings = this.onSaveRiskSettings.bind(this);

    this.validateBoadiceaData = this.validateBoadiceaData.bind(this);
    this.validateClausData = this.validateClausData.bind(this);
    this.validateGailData = this.validateGailData.bind(this);
    this.validateTyrerCuzickData = this.validateTyrerCuzickData.bind(this);
    this.isValidString = this.isValidString.bind(this);
    this.isValidNumber = this.isValidNumber.bind(this);
    this.calculateAge = this.calculateAge.bind(this);

    this.addFatalTermination = this.addFatalTermination.bind(this);
    this.addRequiredData = this.addRequiredData.bind(this);

    this.runRisk = this.runRisk.bind(this);

    this.getRelationToProband = this.getRelationToProband.bind(this);

    this.mapRiskFactorsAPI = this.mapRiskFactorsAPI.bind(this);

    this.handlePrintClick = this.handlePrintClick.bind(this);
    this.subConceptCheck = this.subConceptCheck.bind(this);
    this.buildUpdateDiseasePayload = this.buildUpdateDiseasePayload.bind(this);
    this.createTimeStamp = this.createTimeStamp.bind(this);
    this.buildCentralStoreDisease = this.buildCentralStoreDisease.bind(this);
    this.updateDiseaseInCentralStore = this.updateDiseaseInCentralStore.bind(this);
    this.resetWarningsErrors = this.resetWarningsErrors.bind(this);
    this.createBlob = this.createBlob.bind(this);
    this.addTableToSvg = this.addTableToSvg.bind(this)
    this.newPageCheck = this.newPageCheck.bind(this)

    this.boadiceaCallback = this.boadiceaCallback.bind(this);
    this.clausCallback = this.clausCallback.bind(this);
    this.gailCallback = this.gailCallback.bind(this);
    this.tyrercuzickCallback = this.tyrercuzickCallback.bind(this);
    this.brcaproCallback = this.brcaproCallback.bind(this);
    this.mmrproCallback = this.mmrproCallback.bind(this);
    this.melaproCallback = this.melaproCallback.bind(this);
    this.pancproCallback = this.pancproCallback.bind(this);
    this.qrisk3Callback = this.qrisk3Callback.bind(this);
    this.calculateRisk = this.calculateRisk.bind(this);
    this.diseasesSubconceptCheck = this.diseasesSubconceptCheck.bind(this);

    this.onForceRerun = this.onForceRerun.bind(this)

    this.handleBoadiceaData = this.handleBoadiceaData.bind(this);
  }

  async componentDidMount() {
    try {
        let org = await account_api.get_org_by_id(this.props.session.user.organization_id);
        this.setState({ orgLicense: org.license });
    } catch (err) {
      console.log(err);
    }
    // load risk factors
    try {
      let tokens = sdk.tokens_from_cookie();
      const headers = { accessToken: tokens.token.accessToken };
      let payload = {
        clinician_id: this.props.session.user.clinician_id
      };

      payload.proband_id = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband.id : this.props.patient.patient.id;
      helper_survey_api.get_risk_factor_survey_answers_as_clinician(payload, headers, this.riskFactorsCallback, (error) => {
        console.log(error);
      });
    } catch (err) {
      console.log(err);
    }

    supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch (e) { }

    wheelOpt = supportsPassive ? { passive: false } : false;
    wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
  }

  async riskFactorsCallback(data) {
    let payload = this.mapRiskFactorsAPI(data.risk_factors);
    await this.props.dispatch(patient_actions.save_risk_factors(payload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      await this.props.getPedigreeData().setRiskFactors(payload);
    }
    await this.resetRiskResults();
    await this.runRisk();
  }


  async diseasesSubconceptCheck(family_id, disease_to_check){
    let diseases_no_age_dx = {}
    let payload = {
      'family_id': family_id,
      'disease_to_check': disease_to_check
    }

    try{
      diseases_no_age_dx = await disease_api.check_members_disease_cubconcept(payload)
    }catch(err){
      console.dir(err)
    }
    return diseases_no_age_dx
  }

  probandHasChildren() {
    let partners = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().partners : this.props.patient.partners
    let sons_daughters = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().sons_daughters : this.props.patient.sons_daughters
    if (!('proband' in partners)) return;

    let children = [];
    let unaccountedChildrenCount = 0
    partners.proband.forEach((partner, index) => {
      if (partner.rkey in sons_daughters) {
        children = children.concat(sons_daughters[partner.rkey]);
      }
    });

    // is_no_children_node or is_infertility_node filters should still work
    // since it's still present on the fake child nodes
    children.forEach(child=>{
      if(child.pregnancy || child.adopted_in || child.is_infertility_node || child.is_no_children_node){
      unaccountedChildrenCount++
      }
    })

    if((children.length === unaccountedChildrenCount)){
      return
    }
    return children.length > 0;
  }

  openRiskSettingsModal(event) {
    this.setState({ showRiskSettingsModal: true });
  }

  closeMissingDataModal() {
    this.setState({ showMissingDataModal: false });
  }

  closeRiskSettingsModal() {
    this.setState({ showRiskSettingsModal: false });
  }
  createBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  createTimeStamp(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var mins = String(today.getMinutes()).padStart(2, "0")
    var seconds = String(today.getSeconds()).padStart(2, "0")

    let timeStamp = mm + '-' + dd + '-' + yyyy +'-' + hour +'-' + mins + '-' + seconds;


    return timeStamp

  }

  buildUpdateDiseasePayload(member_id , disease) {
    let payload = {}
    let disease_id = disease.disease_id
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";

    //build skip logic array if skip logic attached to disease
    var skip_logics = [];
    if (disease.skip_logics && disease.skip_logics.length > 0) {
      for (var item of disease.skip_logics) {
        var data_sl = {
          member_id: member_id,
          skip_logic_id: item.skip_logic_id,
          answer: item.answer,
          skip_logic_code: item.skip_logic_code
        };
        skip_logics.push(data_sl);
      }
    }

    if(is_from_umls){
      payload = {
        id: disease.id,
        member_id: member_id,
        disease_id: null,
        umls_id: disease_id,
        age_diagnosed_string: disease.age_diagnosed_string,
        age_diagnosed: disease.age_diagnosed,
        umls_name: disease.disease_name,
        skip_logics: skip_logics
    };
    }else{
      payload = {
          id: disease.id,
          member_id: member_id,
          disease_id: disease_id,
          age_diagnosed: disease.age_diagnosed,
          age_diagnosed_string:  disease.age_diagnosed_string,
          umls_id: null,
          umls_name: null,
          skip_logics: skip_logics
      };
    }

    return payload
  }

  async onSaveMissingData(payload) {
    /*
      {
        rkey: {
          dob: '1985-12-12',
          age: 34,
          yob: 1963
          diseases: {
            disease_id: age_diagnosed
          }
        }
      }
    */
    const proband_rkey = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband.rkey : this.props.patient.patient.rkey;

    for (var key in payload) {

      let fields = payload[key];
      let profile_clone = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? cloneDeep(this.props.getPedigreeData().profiles[key]) : cloneDeep(this.props.patient.profile[key]);

      let member_payload = {};

      let risk_factors = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? cloneDeep(this.props.getPedigreeData().risk_factors) : cloneDeep(this.props.patient.risk_factors);
      if (fields['ageFirstBirth'] !== null && fields['ageFirstBirth'] !== undefined) {

        risk_factors['reproductiveRiskFactors']['ageFirstBirth'] = fields['ageFirstBirth'];

        try {
          let tokens = sdk.tokens_from_cookie();
          const headers = { accessToken: tokens.token.accessToken };
          const payload = {
            proband_id: this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband.id : this.props.patient.patient.id,
            master_question_uuid: rf_constants.FIRST_CHILD_AGE,
            choice_value: fields['ageFirstBirth']
          };

          helper_survey_api.save_risk_factor_answer_as_clinician(payload, headers,
            () => {
              this.props.dispatch(patient_actions.save_risk_factors(risk_factors));
              if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
                this.props.getPedigreeData().setRiskFactors(risk_factors);
              }
            },
            (error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }

      }

      if (fields['ageFirstMenstruation'] !== null && fields['ageFirstMenstruation'] !== undefined) {

        risk_factors['reproductiveRiskFactors']['ageFirstMenstruation'] = fields['ageFirstMenstruation'];

        try {
          let tokens = sdk.tokens_from_cookie();
          const headers = { accessToken: tokens.token.accessToken };
          const payload = {
            proband_id: this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband.id : this.props.patient.patient.id,
            master_question_uuid: rf_constants.FIRST_MENS_AGE,
            choice_value: fields['ageFirstMenstruation']
          };

          helper_survey_api.save_risk_factor_answer_as_clinician(payload, headers,
            () => {
              this.props.dispatch(patient_actions.save_risk_factors(risk_factors));
              if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
                this.props.getPedigreeData().setRiskFactors(risk_factors);
              }
            },
            (error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }

      }

      // set proband data if proband
      if (key === proband_rkey) {
        if (fields['dob'] !== null && fields['dob'] !== undefined) {
          let dataKey = 'dob';
          let data = fields['dob'];
          let payload = { dataKey, data };
          this.props.dispatch(patient_actions.save_patient_data(payload));
          if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
            let deep_clone = cloneDeep(this.props.getPedigreeData().proband);
            deep_clone['dob'] = data;
            let today = new Date();
            let birth_date = new Date(data);
            let age = today.getFullYear() - birth_date.getFullYear();
            let m = today.getMonth() - birth_date.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birth_date.getDate())) {
              age--;
            }
            deep_clone['age'] = age
            await this.props.getPedigreeData().setProband(deep_clone);
          }
        }

        if (fields['age'] !== null && fields['age'] !== undefined) {
          let dataKey = 'age';
          let data = fields['age'];
          let payload = { dataKey, data };
          this.props.dispatch(patient_actions.save_patient_data(payload));
          if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
            let deep_clone = cloneDeep(this.props.getPedigreeData().proband);
            deep_clone['age'] = data
            await this.props.getPedigreeData().setProband(deep_clone);
          }
        }
      }

      // set profile data
      if (fields['age'] !== null && fields['age'] !== undefined) {
        let age = ageStringToAgeFloat(fields['age'])
        let age_string =  normalizeAgeString(fields['age'])
        let yob = ageStringToYOB(fields['age'])
        console.log(yob)
        profile_clone.age = age;
        member_payload.age = age;

        profile_clone.age_string = age_string;
        member_payload.age_string = age_string;

        profile_clone.yob = yob;
        member_payload.yob = yob;
      }

      if (fields['dob'] !== null && fields['dob'] !== undefined) {
        profile_clone.dob = fields['dob'];
        member_payload.dob = fields['dob'];
        profile_clone.yob = moment(fields['dob']).year();
        member_payload.yob = moment(fields['dob']).year();

        let age = moment().year() - moment(fields['dob']).year();
        if (moment().dayOfYear() < moment(fields['dob']).dayOfYear()) age -= 1;
        if (age >= 0) {
          profile_clone.age = age;
          member_payload.age = age;
        }
      }

      if (fields['yob'] !== null && fields['yob'] !== undefined) {
        profile_clone.yob = fields['yob'];
        member_payload.yob = fields['yob'];
        profile_clone.age =  fields['age'];
        member_payload.age =  fields['age'];

      }

      // save profile data to redux
      // helper_family_tree.saveProfileToRedux(this.props.dispatch, profile_clone);
      if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
        await this.props.getPedigreeData().setProfile(profile_clone.rkey, profile_clone)
      }
      if (key === proband_rkey) {
        // helper_family_tree.savePatientToRedux(this.props.dispatch, profile_clone);
        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          await this.props.getPedigreeData().setProband(profile_clone)
        }
      }

      // set disease age diagnosed
      if (fields['diseases'] !== null && fields['diseases'] !== undefined) {
        var cloned_diseases = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().diseases[key] : this.props.patient.history_diseases[key];
        for (var i = 0; i < cloned_diseases.length; i++) {
          if (fields['diseases'][cloned_diseases[i].id] !== null && fields['diseases'][cloned_diseases[i].id] !== undefined) {
            let cloned_disease = cloneDeep(cloned_diseases[i]);
            let cloned_disease_value = fields['diseases'][cloned_diseases[i].id];

            let ageKey = ''
            let ageString = ''
            let ageOption = cloned_disease_value.replace(/\d+/g, '').trim();
            let ageInt = parseInt(cloned_disease_value, radix_bases.base10)
            for(let key in ageOptions){
               if(ageOption.startsWith(ageOptions[key])){
                 ageKey = key
               }
             }
            ageString = (ageInt && ageKey!== '') ?  `${ageInt}${ageKey}` : ageInt ? `${ageInt}` : ''
            cloned_disease.age_diagnosed_string = ageString;
            cloned_disease.age_diagnosed = stringToAge(ageInt, ageKey);

            // save cloned diseases to redux
            // helper_health_history.saveHistoryDiseaseDetailToRedux(this.props.dispatch, key, cloned_disease);

            // ***** TODO: Setting a specific history disease of the member on the new pedigree data store. Consider
            // diseases type if normal or umls etc. *****
            // if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
            //   await this.props.getPedigreeData().setDiseases(key, cloned_disease)
            // }

            // update diseases on server
            // Save member disease to API
            let disease_payload = this.buildUpdateDiseasePayload(profile_clone.id, cloned_disease)

            //update central store with new disease data
            if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
              let new_disease = this.buildCentralStoreDisease(cloned_disease)
              let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(profile_clone.rkey))
              let diseases = cloneDeep(this.props.getPedigreeData().getDiseases(profile_clone.rkey));
              this.updateDiseaseInCentralStore(profile_proband, diseases, new_disease)
            }

            try{
              await disease_api.save_disease(disease_payload);
            }catch (error) {
              helpers.logger(error);
            }
          }
        }
      }

      // update member data on server
      var member = null;
      try {
        member = await family_api.patch_member_memberid(profile_clone.id, member_payload);
      } catch (error) {
        helpers.logger(error);
      }

    }

    this.calculateRisk();
  }


  buildCentralStoreDisease(disease){

    const is_from_umls = isString(disease.disease_id) ? disease.disease_id.substr(0,1) === "C" : false
    let newDiseasePayload ={}
      newDiseasePayload.age_diagnosed = disease.age_diagnosed
      newDiseasePayload.age_diagnosed_string = disease.age_diagnosed_string
      newDiseasePayload.disease_code = disease.disease_code
      newDiseasePayload.disease_id = disease.disease_id
      newDiseasePayload.disease_name = disease.disease_name
      newDiseasePayload.disease_short_name = disease.disease_short_name
      newDiseasePayload.id = disease.id
      newDiseasePayload.rkey = disease.rkey
      newDiseasePayload.skip_logics = cloneDeep(disease.skip_logics)

    if(is_from_umls){
      newDiseasePayload.umls_id = disease.disease_id;
      newDiseasePayload.umls_name = disease.umls_name
    } else{
      newDiseasePayload.umls_id = null
      newDiseasePayload.umls_name = null
    }

    return newDiseasePayload
}

updateDiseaseInCentralStore(profile_clone, diseases, new_disease){
  //Shouldnt need else statement since cant add new disease from risk assessment tab, only update diseases
  if (diseases){
    var index = diseases.findIndex(disease => disease.rkey === new_disease.rkey);
    if (index > -1) {
      diseases[index] = new_disease;
    } else{
      diseases.push(new_disease);
    }
    profile_clone.diseases = diseases;
    this.props.getPedigreeData().setProfile(profile_clone.rkey, profile_clone);
    this.props.getPedigreeData().setDiseases(profile_clone.rkey, diseases);
  }
}

  mapRiskFactorsAPI(api_risk_factors) {
    let payload = default_risk_factors;
    payload.risk_factor_id = api_risk_factors.id;

    payload.generalRiskFactors.weightUnitSystem = 'pounds';
    payload.generalRiskFactors.weightPounds = api_risk_factors.weight;
    payload.generalRiskFactors.weightKilograms = 0;
    if (api_risk_factors.weight_metric === 'kg') {
      payload.generalRiskFactors.weightUnitSystem = 'kilograms';
      payload.generalRiskFactors.weightPounds = 0;
      payload.generalRiskFactors.weightKilograms = api_risk_factors.weight;
    }

    payload.generalRiskFactors.heightUnitSystem = 'uss';
    payload.generalRiskFactors.heightFeet = api_risk_factors.height_first_num;
    payload.generalRiskFactors.heightInches = api_risk_factors.height_second_num;
    payload.generalRiskFactors.heightMeters = 0;
    payload.generalRiskFactors.heightCentimeters = 0;
    if (api_risk_factors.height_metric === 'meter') {
      payload.generalRiskFactors.heightUnitSystem = 'meters';
      payload.generalRiskFactors.heightMeters = api_risk_factors.height_first_num;
      payload.generalRiskFactors.heightCentimeters = api_risk_factors.height_second_num;
      payload.generalRiskFactors.heightFeet = 0;
      payload.generalRiskFactors.heightInches = 0;
    }
    payload.generalRiskFactors.bmi = 0;


    payload.reproductiveRiskFactors.ageFirstMenstruation = api_risk_factors.age_at_first_menstruation;
    payload.reproductiveRiskFactors.ageFirstBirth = api_risk_factors.age_at_first_birth;
    payload.reproductiveRiskFactors.postMenopausal = api_risk_factors.post_menopausal;
    payload.reproductiveRiskFactors.ageAtMenopause = api_risk_factors.age_at_menopause;
    payload.reproductiveRiskFactors.takenHRT = api_risk_factors.ever_taken_hrt;
    payload.reproductiveRiskFactors.howManyYearsOnHRT = api_risk_factors.hrt_years;
    payload.reproductiveRiskFactors.typeOfHRT = api_risk_factors.hrt_type;
    payload.reproductiveRiskFactors.yearsSinceLastTakenHRT = api_risk_factors.year_since_last_taken;
    payload.reproductiveRiskFactors.yearsOfIntendedUseOfHRT = api_risk_factors.intended_use;

    payload.proceduresRiskFactors.breastDensityMethod = api_risk_factors.breast_density_method;
    payload.proceduresRiskFactors.biRadsDensity = api_risk_factors.birads_density;
    payload.proceduresRiskFactors.percentageDensity = api_risk_factors.percentage_density;
    payload.proceduresRiskFactors.hadBreastBiopsy = api_risk_factors.ever_had_biopsy;
    payload.proceduresRiskFactors.amountOfBreastBiopsies = api_risk_factors.biopsy_number;
    payload.proceduresRiskFactors.breastBiopsyBenign = api_risk_factors.benign;
    payload.proceduresRiskFactors.breastBiopsyHyperplasia = api_risk_factors.hyperplasia;
    payload.proceduresRiskFactors.breastBiopsyAtypicalHyperplasia = api_risk_factors.atypical_hyperplasia;
    payload.proceduresRiskFactors.breastBiopsyLCIS = api_risk_factors.lcis;
    payload.proceduresRiskFactors.breastBiopsyUnknown = api_risk_factors.unknown;

    payload.surgeriesRiskFactors.hadHysterectomy = api_risk_factors.hysterectomy;
    payload.surgeriesRiskFactors.ageAtHysterectomy = api_risk_factors.hysterectomy_age;
    payload.surgeriesRiskFactors.hadMastectomy = api_risk_factors.mastectomy;
    payload.surgeriesRiskFactors.ageAtMastectomy = api_risk_factors.mastectomy_age;
    payload.surgeriesRiskFactors.typeOfMastectomy = api_risk_factors.mastectomy_type;
    payload.surgeriesRiskFactors.hadOophorectomy = api_risk_factors.oophorectomy;
    payload.surgeriesRiskFactors.ageAtOophorectomy = api_risk_factors.oophorectomy_age;
    payload.surgeriesRiskFactors.typeOfOophorectomy = api_risk_factors.oophorectomy_type;
    payload.surgeriesRiskFactors.hadSalpingectomy = api_risk_factors.salpingectomy;
    payload.surgeriesRiskFactors.ageAtSalpingectomy = api_risk_factors.salpingectomy_age;
    payload.surgeriesRiskFactors.hadTubalLigation = api_risk_factors.tubal_ligation;
    payload.surgeriesRiskFactors.ageAtTubalLigation = api_risk_factors.tubal_ligation_age;

    return payload;
  }

  resetWarningsErrors(){
    this.setState({
      boadiceaRuntimeErrors: [],
      clausRuntimeErrors: [],
      gailRuntimeErrors: [],
      tyrerCuzickRuntimeErrors: [],
      brcaproRuntimeErrors: [],
      mmrproRuntimeErrors: [],
      pancproRuntimeErrors: [],
      melaproRuntimeErrors: [],
      qrisk3RuntimeErrors: [],
      boadiceaRuntimeWarnings: [],
      brcaproRuntimeWarnings: [],
      mmrproRuntimeWarnings: [],
      pancproRuntimeWarnings: [],
      melaproRuntimeWarnings: [],
      qrisk3RuntimeWarnings: []
    })
  }

  async onSaveRiskSettings(payload) {
    let cloned_payload = Object.assign({}, payload);
    this.resetWarningsErrors();
    // save the boadicea_incidence_rates
    if (cloned_payload.license.id && cloned_payload.license.id !== "") {
      await account_api.patch_license(cloned_payload.license.id, {
        boadicea_incidence_rates: cloned_payload.boadicea_incidence_rates,
      });
    }

    // delete the license from payload before sending to save risk setting
    delete cloned_payload.license;

    let data = null;

    if (!cloned_payload.boadicea_incidence_rates) {
      cloned_payload.boadicea_incidence_rates = "USA";
    }

    // create/save the users risk_setting record and update the redux payload with the db data
    let risk_settings = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_settings : this.props.patient.risk_settings
    if (risk_settings.user) {

      data = await risk_api.save_risk_setting(cloned_payload);

    } else {

      cloned_payload.user = this.props.session.user.user_id;
      data = await risk_api.create_risk_setting(cloned_payload);

    }

    if (data) {

      data.competing_mortality = cloned_payload.competing_mortality;
      data.premm = false;

      await this.props.dispatch(patient_actions.save_risk_settings(data));
      if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
        await this.props.getPedigreeData().setRiskSettings(data)
      }

      // Retrieve and check if cookie exists
      // update the profile in the browser cookie
      try {

        this.props.dispatch(patient_actions.save_risk_settings(data));
        await this.runRisk();

      } catch (err) {
        console.log(err);
      }

    }
  }

  async onForceRerun(){
    const proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient;
    let payload = {
      proband_id: proband.id
    }

    try {
      await risk_api.reset_hash_input_data(payload);
      await this.runRisk();
    } catch (err) {
      console.log(err);
    }

    //call endpoint to delete the members risk
  }

  // TODO: move these input data checks server side as well
  async validateBoadiceaData(risk_setup) {
    const proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient;
    const boadicea = settings.app_constants.riskModels.boadicea;
    let disease_subconcepts = {}

    /**************************************************
    ******************Proband Checks*******************
    ***************************************************/

    // check if a birth date exists
    const bday = proband.dob;
    if ((!this.isValidString(bday) || (bday.trim().match(BDAY_REGEX).length === 0)) && proband.yob === null) {
      let msg = 'Year of Birth must be present to run Boadicea risk algorithm, therefore you must input patient birth date';
      let data_field = { fieldLabel: 'Birth Date', fieldName: 'dob', fieldPayload: bday, message: msg };
      this.addRequiredData(risk_setup, boadicea, 'Proband', true, proband.first_name, proband.rkey, data_field);
    }

    // check if an age or history entry with an age of death exists
    const age = proband.age;
    if (!this.isValidNumber(age) && proband.yob === null) {
      const history_entry = this.props.patient.history[proband.rkey];
      if (history_entry === null || history_entry === undefined || !this.isValidNumber(history_entry.age_death)) {
        let msg = 'Age or Age at death must be present to run Boadicea risk algorithm, therefore you must input patient age';
        let data_field = { fieldLabel: 'Age', fieldName: 'age', fieldPayload: age, message: msg };
        this.addRequiredData(risk_setup, boadicea, 'Proband', true, proband.first_name, proband.rkey, data_field);
      }
    }

    // set functions to variable so it will be available inside lodash callbacks
    const isValidString = this.isValidString;
    const isValidNumber = this.isValidNumber;

    const history_diseases_entry = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().diseases[proband.rkey] : this.props.patient.history_diseases[proband.rkey];
    let history_diseases_obj = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().diseases : this.props.patient.history_diseases;
    if((history_diseases_entry !== null && history_diseases_entry !== undefined) || (history_diseases_obj !== null && history_diseases_obj !== undefined)){
      disease_subconcepts = await this.diseasesSubconceptCheck(proband.family_id, BoadiceaDiseaseCodes)
    }

    if (history_diseases_entry !== null && history_diseases_entry !== undefined) {
      for(var disease of history_diseases_entry ){
        let member_id = proband.id
        let member_boadicea_disease = disease_subconcepts[member_id]
        let val = disease.disease_id
        if(member_boadicea_disease){
          for(const boadiceaDisease of member_boadicea_disease){
            if(boadiceaDisease == val){
              if (!this.isValidNumber(disease.age_diagnosed)) {
                const msg = 'Age at Diagnosis must be present to run Boadicea risk algorithm';
                let data_field = { fieldLabel: 'Age DX', fieldName: 'age_diagnosed', fieldPayload: disease, message: msg };
                this.addRequiredData(risk_setup, boadicea, 'Proband', true, proband.first_name, proband.rkey, data_field);
              }
            }
          }
        }
      }
    }


    /**************************************************
    ******************Relative Checks******************
    ***************************************************/
    // if relative with (breast, ovarian, pancreatic or prostate cancer) age/age at death is blank or null/undefined
    // if relative with (breast, ovarian, pancreatic or prostate cancer) Year of Birth is blank or null/undefined
    // loop through all the history_diseases object checking for one of the cancers
    let profile_obj = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().profiles : this.props.patient.profile;
    for (var key in history_diseases_obj) {
      let member_diseases = history_diseases_obj[key];

      let member_profile = profile_obj[key];
      let member_boadicea_disease = disease_subconcepts[member_profile.id]
      let member_history = this.props.patient.history[key];
      const member_bday = member_profile.dob;
      const member_age = member_profile.age;
      const is_proband = member_profile.is_proband;
      const yob = member_profile.yob;
      const living = !member_profile.is_dead;
      if (!is_proband) {
        //if disease equals val it means the user has a boadicea disease so set boadicea diagnosis to true to then check for age/yob
        let boadiceaDiseaseDiagnosis = false
        for (let i = 0; i < member_diseases.length; i++) {
          let val = member_diseases[i].disease_id;
          if(member_boadicea_disease){
            for(const boadiceaDisease of member_boadicea_disease){
              if(boadiceaDisease == val){
                boadiceaDiseaseDiagnosis = true
                if (!this.isValidNumber(member_diseases[i].age_diagnosed)) {
                  let msg = 'Age Diagnosed must be present to run Boadicea risk algorithm, therefore you must input cancer age diagnosed';
                  let data_field = { fieldLabel: 'Age DX', fieldName: 'age_diagnosed', fieldPayload: member_diseases[i], message: msg };
                  this.addRequiredData(risk_setup, boadicea, this.getRelationToProband(member_profile.rkey), false, member_profile.first_name, member_profile.rkey, data_field);
                }
              }
            }
          }
        }

        if (!this.isValidNumber(yob) && boadiceaDiseaseDiagnosis) {
          // check for dob
          if (!this.isValidString(member_bday) || member_bday.trim().match(BDAY_REGEX).length === 0) {

            // check for age if not deceased
            if (living && !this.isValidNumber(member_age)) {
              let msg = 'Year of Birth must be present to run Boadicea risk algorithm, therefore you must input relatives YOB';
              let data_field = { fieldLabel: 'YOB', fieldName: 'yob', fieldPayload: yob, message: msg };
              // if(!risk_setup.requiredData.data_field && !risk_setup.requiredData.data_field.includes(data_field))
              this.addRequiredData(risk_setup, boadicea, this.getRelationToProband(member_profile.rkey), false, member_profile.first_name, member_profile.rkey, data_field);
            }
          }
        }

        // check if an age or history entry with an age of death exists
        if (!this.isValidNumber(member_age) && boadiceaDiseaseDiagnosis) {
          if (member_history === null || member_history === undefined || !this.isValidNumber(member_history.age_death)) {
            let msg = 'Age must be present to run Boadicea risk algorithm, therefore you must input relatives age';
            let data_field = { fieldLabel: 'Age', fieldName: 'age', fieldPayload: member_age, message: msg };
            // if(!risk_setup.requiredData.data_field && !risk_setup.requiredData.data_field.includes(data_field))
            this.addRequiredData(risk_setup, boadicea, this.getRelationToProband(member_profile.rkey), false, member_profile.first_name, member_profile.rkey, data_field);
          }
        }

      }
    }

    // TODO: for relatives not affected with cancer, check for age or age at death & year of birth
  }

  async validateClausData(risk_setup) {
    const proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient;
    const claus = settings.app_constants.riskModels.claus;
    // check if an age or history entry with an age of death exists
    const age = proband.age;
    if (!this.isValidNumber(age)) {
      const history_entry = this.props.patient.history[proband.rkey];
      if (history_entry === null || history_entry === undefined || !this.isValidNumber(history_entry.age_death)) {
        let msg = 'Age or Age at death must be present to run CLAUS risk algorithm, therefore you must input patient age';
        let data_field = { fieldLabel: 'Age', fieldName: 'age', fieldPayload: age, message: msg };
        this.addRequiredData(risk_setup, claus, 'Proband', true, proband.first_name, proband.rkey, data_field);
      }
    }

    // set functions to variable, this won't be available inside lodash callbacks
    const isValidString = this.isValidString;
    const isValidNumber = this.isValidNumber;
    const addRequiredData = this.addRequiredData;
    const subConceptCheck = this.subConceptCheck
    const getRelationToProband = this.getRelationToProband;
    const profiles = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().profiles : this.props.patient.profile;

    // if relative with breast cancer age_of_dx is blank or null/undefined
    // breast cancer from diseasesfromapi.json
    //if disease id is standard id dont make api call to check if disease is a subconcept of breast
    let history_diseases_obj = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().diseases : this.props.patient.history_diseases;
    let profile_obj = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().profiles : this.props.patient.profile;

    let disease_subconcepts = {}
    if(history_diseases_obj !== null && history_diseases_obj !== undefined){
      disease_subconcepts = await this.diseasesSubconceptCheck(proband.family_id, BoadiceaDiseaseCodes)
    }

    for (var key in history_diseases_obj) {
      let member_diseases = history_diseases_obj[key];
      let member_profile = profile_obj[key];
      const is_proband = member_profile.is_proband;
      let member_boadicea_disease = disease_subconcepts[member_profile.id]

      if (!is_proband) {
        for (let i = 0; i < member_diseases.length; i++) {
          let val = member_diseases[i].disease_id;
          if(member_boadicea_disease){
            for(const boadiceaDisease of member_boadicea_disease){
              if(boadiceaDisease == val){
                if (!this.isValidNumber(member_diseases[i].age_diagnosed)) {
                  let member_profile = profiles[key];
                  const name = member_profile.first_name;
                  const msg = 'Age Diagnosed must be present to run Claus risk algorithm';
                    let data_field = { fieldLabel: 'Age DX', fieldName: 'age_diagnosed', fieldPayload: member_diseases[i], message: msg };
                    addRequiredData(risk_setup, claus, getRelationToProband(member_profile.rkey), false, name, key, data_field);
                }
              }
            }
          }
        }
      }
    }

    if (risk_setup.fatalTerminations.claus.terminations.length > 0) risk_setup.fatalTerminations.claus.terminate = true;
  }

  validateGailData(risk_setup) {
    const proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient;
    const gender = proband.gender;
    if (gender === "f") {

      const gail = settings.app_constants.riskModels.gail;

      const bday = proband.dob;
      const age = proband.age;

      if (!this.isValidString(bday) || bday.trim().match(BDAY_REGEX).length === 0) {
        if (!this.isValidNumber(age)) {
          const msg = 'Age must be present to run Gail risk algorithm, therefore you must input patient age';
          let data_field = { fieldLabel: 'Age', fieldName: 'age', fieldPayload: age, message: msg };
          this.addRequiredData(risk_setup, gail, 'Proband', true, proband.first_name, proband.rkey, data_field);
        }
      }

      let risk_factors = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_factors : this.props.patient.risk_factors;
      const age_first_menstruation = risk_factors.reproductiveRiskFactors.ageFirstMenstruation;
      if (!this.isValidNumber(age_first_menstruation)) {
        const msg = 'Age of first menstruation must be present to run Gail risk algorithm';
        let data_field = { fieldLabel: 'Age of first menstruation', fieldName: 'ageFirstMenstruation', fieldPayload: age_first_menstruation, message: msg };
        this.addRequiredData(risk_setup, gail, 'Proband', true, proband.first_name, proband.rkey, data_field);
      }

      // check to see if the proband has any children, if not use 98 as the age, otherwise require an age
      if (this.probandHasChildren()) {
        const age_first_birth = risk_factors.reproductiveRiskFactors.ageFirstBirth;
        if (!this.isValidNumber(age_first_birth)) {
          const msg = 'Age of first birth must be present to run Gail risk algorithm';
          let data_field = { fieldLabel: 'Age of first birth', fieldName: 'ageFirstBirth', fieldPayload: age_first_birth, message: msg };
          this.addRequiredData(risk_setup, gail, 'Proband', true, proband.first_name, proband.rkey, data_field);
        }
      }

      if (risk_setup.fatalTerminations.gail.terminations.length > 0) risk_setup.fatalTerminations.gail.terminate = true;
    }
}

  validateTyrerCuzickData(risk_setup) {
    const proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient;
    const tyrerCuzick = settings.app_constants.riskModels.tyrer_cuzick;

    const gender = proband.gender;
    if (gender === "f") {

      const bday = proband.dob;
      const age = proband.age;

      if (!this.isValidString(bday) || bday.trim().match(BDAY_REGEX).length === 0) {
        if (!this.isValidNumber(age)) {
          const msg = 'Age must be present to run Tyrer Cuzick risk algorithm, therefore you must input patient age';
          let data_field = { fieldLabel: 'Age', fieldName: 'age', fieldPayload: age, message: msg };
          this.addRequiredData(risk_setup, tyrerCuzick, 'Proband', true, proband.first_name, proband.rkey, data_field);
        }
      }
    }
  }

  getRelationToProband(rkey) {
    let proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient
    let partners = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().partners : this.props.patient.partners
    let uncles_aunts = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().uncles_aunts : this.props.patient.uncles_aunts
    let siblings = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().siblings : this.props.patient.siblings
    let sons_daughters = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().sons_daughters : this.props.patient.sons_daughters
    let proband_id = proband.id
    let proband_spouse_ids = []

    if (partners['proband'] !== null && partners['proband'] !== undefined) {
      for (let x = 0; x < partners['proband'].length; x++) {
        if (partners['proband'][x].rkey === rkey) {
         proband_spouse_ids.push(partners['proband'][x].id)
        }
      }
      proband_spouse_ids.push(proband_id)
    }
    // check partners
    if (partners['proband'] !== null && partners['proband'] !== undefined) {
      for (let x = 0; x < partners['proband'].length; x++) {
        if (partners['proband'][x].rkey === rkey) {
          return "Partner #" + String(x + 1);
        }
      }
    }

    // check father
    if (proband.father !== null && proband.father !== undefined) {
      if (proband.father.rkey === rkey) {
        return "Father";
      }
    }

    // check mother
    if (proband.mother !== null && proband.mother !== undefined) {
      if (proband.mother.rkey === rkey) {
        return "Mother";
      }
    }

    // check maternal grandmother
    if (proband.maternal_grandmother !== null && proband.maternal_grandmother !== undefined) {
      if (proband.maternal_grandmother.rkey === rkey) {
        return "Maternal Grandmother";
      }
    }

    // check maternal grandfather
    if (proband.maternal_grandfather !== null && proband.maternal_grandfather !== undefined) {
      if (proband.maternal_grandfather.rkey === rkey) {
        return "Maternal Grandfather";
      }
    }

    // check paternal grandmother
    if (proband.paternal_grandmother !== null && proband.paternal_grandmother !== undefined) {
      if (proband.paternal_grandmother.rkey === rkey) {
        return "Paternal Grandmother";
      }
    }

    // check paternal grandfather
    if (proband.paternal_grandfather !== null && proband.paternal_grandfather !== undefined) {
      if (proband.paternal_grandfather.rkey === rkey) {
        return "Paternal Grandfather";
      }
    }

    // check maternal aunts & uncles
    if (uncles_aunts['maternal'] !== null && uncles_aunts['maternal'] !== undefined) {
      for (let x = 0; x < uncles_aunts['maternal'].length; x++) {
        if (uncles_aunts['maternal'][x].rkey === rkey) {
          if (uncles_aunts['maternal'][x].gender === 'm') {
            let uncles_maternal_copy = JSON.parse(JSON.stringify(uncles_aunts['maternal']))
            uncles_maternal_copy = uncles_maternal_copy.filter(person => person.gender === 'm')
            uncles_maternal_copy.sort((a, b) => a.id - b.id);
            let index = uncles_maternal_copy.map(object => object.rkey).indexOf(`apimem-${uncles_aunts['maternal'][x].id}`);
            return 'Maternal Uncle #' + String(index + 1);
          } else if (uncles_aunts['maternal'][x].gender === 'f') {
            let aunts_maternal_copy = JSON.parse(JSON.stringify(uncles_aunts['maternal']))
            aunts_maternal_copy = aunts_maternal_copy.filter(person => person.gender === 'f')
            aunts_maternal_copy.sort((a, b) => a.id - b.id);
            let index = aunts_maternal_copy.map(object => object.rkey).indexOf(`apimem-${uncles_aunts['maternal'][x].id}`);
            return 'Maternal Aunt #' + String(index + 1);
          }
        }
      }
    }

    // check paternal aunts & uncles
    if (uncles_aunts['paternal'] !== null && uncles_aunts['paternal'] !== undefined) {
      for (let x = 0; x < uncles_aunts['paternal'].length; x++) {
        if (uncles_aunts['paternal'][x].rkey === rkey) {
          if (uncles_aunts['paternal'][x].gender === 'm') {
            let uncles_paternal_copy = JSON.parse(JSON.stringify(uncles_aunts['paternal']))
            uncles_paternal_copy = uncles_paternal_copy.filter(person => person.gender === 'm')
            uncles_paternal_copy.sort((a, b) => a.id - b.id);
            let index = uncles_paternal_copy.map(object => object.rkey).indexOf(`apimem-${uncles_aunts['paternal'][x].id}`);
            return 'Paternal Uncle #' + String(index + 1);
          } else if (uncles_aunts['paternal'][x].gender === 'f') {
            let aunts_paternal_copy = JSON.parse(JSON.stringify(uncles_aunts['paternal']))
            aunts_paternal_copy = aunts_paternal_copy.filter(person => person.gender === 'f')
            aunts_paternal_copy.sort((a, b) => a.id - b.id);
            let index = aunts_paternal_copy.map(object => object.rkey).indexOf(`apimem-${uncles_aunts['paternal'][x].id}`);
            return 'Paternal Aunt #' + String(index + 1);
          }
        }
      }
    }

    // check siblings
    if (siblings !== null && siblings !== undefined) {
      for (let x = 0; x < siblings.length; x++) {
        if (siblings[x].rkey === rkey) {
          if (siblings[x].gender === 'm') {
            let brothers_copy = JSON.parse(JSON.stringify(siblings))
            brothers_copy = brothers_copy.filter(person => person.gender === 'm')
            brothers_copy.sort((a, b) => a.id - b.id);
            let index = brothers_copy.map(object => object.rkey).indexOf(`apimem-${siblings[x].id}`);
            return 'Brother #' + String(index + 1);
          } else if (siblings[x].gender === 'f') {
            let sisters_copy = JSON.parse(JSON.stringify(siblings))
            sisters_copy = sisters_copy.filter(person => person.gender === 'f')
            sisters_copy.sort((a, b) => a.id - b.id);
            let index = sisters_copy.map(object => object.rkey).indexOf(`apimem-${siblings[x].id}`);
            return 'Sister #' + String(index + 1);
          }
        }
      }
    }

    // check children
    // for each spouse in sons_daughters
    //loops through and marks cousins as sons/ daughters
    //check to see if the proband is a parent of the individual and mark as
    for (var spouse in sons_daughters) {
      for (let i = 0; i < sons_daughters[spouse].length; i++) {
        // let probandIsParent = (sons_daughters[spouse][i].id == proband_id || proband_spouse_ids.includes(sons_daughters[spouse][i].id))
        let probandIsParent = (sons_daughters[spouse][i].mother_id == proband_id || sons_daughters[spouse][i].father_id == proband_id)
        if (sons_daughters[spouse][i].rkey === rkey ) {
          if (sons_daughters[spouse][i].gender === 'm' && probandIsParent) {
            let sons_daughters_copy = JSON.parse(JSON.stringify(sons_daughters[spouse]))
            let sons_copy = sons_daughters_copy.filter(person => person.gender === 'm' && (person.mother_id == proband_id || person.father_id == proband_id))
            sons_copy.sort((a, b) => a.id - b.id);
            let index = sons_copy.map(object => object.rkey).indexOf(sons_daughters[spouse][i].rkey);
            return 'Son #' + String(index + 1);
          } else if (sons_daughters[spouse][i].gender === 'f' && probandIsParent) {
            let sons_daughters_copy = JSON.parse(JSON.stringify(sons_daughters[spouse]))
            let daughters_copy = sons_daughters_copy.filter(person => person.gender === 'f' && (person.mother_id == proband_id || person.father_id == proband_id))
            daughters_copy.sort((a, b) => a.id - b.id);
            let index = daughters_copy.map(object => object.rkey).indexOf(sons_daughters[spouse][i].rkey);
            return 'Daughter #' + String(index + 1);
          }else{
            let sons_daughters_copy = JSON.parse(JSON.stringify(sons_daughters[spouse]))
            let cousins_copy = sons_daughters_copy.filter(person => (person.mother_id != proband_id && person.father_id != proband_id))
            cousins_copy.sort((a, b) => a.id - b.id);
            let index = cousins_copy.map(object => object.rkey).indexOf(sons_daughters[spouse][i].rkey);
            return 'Cousin #' + String(index + 1);
          }
        }
      }
    }

    return '';
  }

  isValidString(value) {
    if (value === null || value === undefined || typeof (value) !== 'string' || value.trim() === '') { return false; }
    return true;
  }

  isValidNumber(value) {
    if (isNaN(parseInt(value, radix_bases.base10)) || value < 0) { return false; }
    return true;
  }

  async subConceptCheck(member_id, diseases){
    let subConcept = false

    let payload ={
      member_id: member_id,
      risk_diseases_to_checK: diseases
    }

    try{
      subConcept = await disease_api.check_disease_is_subconcept(payload)
    }catch(err){
      console.dir(err)
    }

    return subConcept
  }

  calculateAge(bday) {
    let today = new Date();
    let birth_date = new Date(bday);
    let age = today.getFullYear() - birth_date.getFullYear();
    let m = today.getMonth() - birth_date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth_date.getDate())) {
      age--;
    }
    return age;
  }

  addFatalTermination(risk_setup, key, model, field_label, message) {
    risk_setup.fatalTerminations[key].terminations.push({
      model: model,
      fieldLabel: field_label,
      message: message
    });
    risk_setup.fatalTerminations[key].terminate = true;
  }

  addRequiredData(risk_setup, model, relationship_to_proband, is_proband, name, rkey, data_field) {

    let prevIndex = risk_setup.requiredData.findIndex(data=> {
      let models = data.model.split('/')
      return (data.rkey == rkey && data.dataField.fieldLabel == data_field.fieldLabel && !models.includes(model) && isEqual(data.dataField.fieldPayload, data_field.fieldPayload))
    })


    if(prevIndex >= 0){
      risk_setup.requiredData[prevIndex].model += `/${model}`
    }else{
      risk_setup.requiredData.push({
        model: model,
        relationshipToProband: relationship_to_proband,
        is_proband: is_proband,
        name: name,
        rkey: rkey,
        dataField: data_field
      });
    }
  }

  async resetRiskResults(){
    let boadiceaData = {
      breast: { fiveYear: 'N/A', lifetime: 'N/A' },
      ovarian: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: [],
      population_cancer_risks: [],
      mutation_probabilities: [
        { "no mutation": { decimal: "", percent: "N/A" } },
        { BRCA1: { decimal: "", percent: "N/A" } },
        { BRCA2: { decimal: "", percent: "N/A" } },
        { PALB2: { decimal: "", percent: "N/A" } },
        { CHEK2: { decimal: "", percent: "N/A" } },
        { ATM: { decimal: "", percent: "N/A" } },
        { BARD1: {decimal: "", percent: "N/A"} },
        { RAD51D: {decimal: "", percent: "N/A"} },
        { RAD51C: {decimal: "", percent: "N/A"} },
        { BRIP1: {decimal: "", percent: "N/A"} }
      ],
      nice_risk_results: []
    };

    let boadiceaPayload = {};
    boadiceaPayload.dataKey = 'boadicea';
    boadiceaPayload.data = boadiceaData;
    await this.props.dispatch(patient_actions.save_risk_results(boadiceaPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['boadicea'] = boadiceaData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }


    let clausData = {
      breast: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: []
    };

    let clausPayload = {};
    clausPayload.dataKey = 'claus';
    clausPayload.data = clausData;
    this.props.dispatch(patient_actions.save_risk_results(clausPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['claus'] = clausData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let gailData = {
      breast: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: []
    };

    let gailPayload = {};
    gailPayload.dataKey = 'gail';
    gailPayload.data = gailData;
    this.props.dispatch(patient_actions.save_risk_results(gailPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['gail'] = gailData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let tyrerCuzickData = {
      breast: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: [],
      mutation_probabilities: [
        { "no mutation": { decimal: "", percent: "N/A" } },
        { BRCA1: { decimal: "", percent: "N/A" } },
        { BRCA2: { decimal: "", percent: "N/A" } }
      ]
    };

    let tyrerCuzickPayload = {};
    tyrerCuzickPayload.dataKey = 'tyrer_cuzick';
    tyrerCuzickPayload.data = tyrerCuzickData;
    await this.props.dispatch(patient_actions.save_risk_results(tyrerCuzickPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['tyrer_cuzick'] = tyrerCuzickData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let brcaProData = {
      breast: { fiveYear: 'N/A', lifetime: 'N/A' },
      ovarian: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: [],
      mutation_probabilities: [
        { "no mutation": { decimal: "", percent: "N/A" } },
        { BRCA1: { decimal: "", percent: "N/A" } },
        { BRCA2: { decimal: "", percent: "N/A" } }
      ]
    };

    let brcaProPayload = {};
    brcaProPayload.dataKey = 'brcapro';
    brcaProPayload.data = brcaProData;
    await this.props.dispatch(patient_actions.save_risk_results(brcaProPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['brcapro'] = brcaProData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let mmrProData = {
      colon: { fiveYear: 'N/A', lifetime: 'N/A' },
      uterine_endometrial: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: [],
      mutation_probabilities: [
        { "no mutation": { decimal: "", percent: "N/A" } },
        { MLH1: { decimal: "", percent: "N/A" } },
        { MSH2: { decimal: "", percent: "N/A" } },
        { MSH6: { decimal: "", percent: "N/A" } }
      ]
    };

    let mmrProPayload = {};
    mmrProPayload.dataKey = 'mmrpro';
    mmrProPayload.data = mmrProData;
    await this.props.dispatch(patient_actions.save_risk_results(mmrProPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['mmrpro'] = mmrProData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let pancProData = {
      pancreatic: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: [],
      mutation_probabilities: [
        { "no mutation": { decimal: "", percent: "N/A" } },
        { pancreaticGene: { decimal: "", percent: "N/A" } }
      ]
    };

    let pancProPayload = {};
    pancProPayload.dataKey = 'pancpro';
    pancProPayload.data = pancProData;
    await this.props.dispatch(patient_actions.save_risk_results(pancProPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['pancpro'] = pancProData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let melaProData = {
      melanoma: { fiveYear: 'N/A', lifetime: 'N/A' },
      cancer_risks: [],
      mutation_probabilities: [
        { "no mutation": { decimal: "", percent: "N/A" } },
        { P16: { decimal: "", percent: "N/A" } }
      ]
    };

    let melaProPayload = {};
    melaProPayload.dataKey = 'melapro';
    melaProPayload.data = melaProData;
    await this.props.dispatch(patient_actions.save_risk_results(melaProPayload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['melapro'] = melaProData;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }

    let qrisk3Data = {
      cardiovascular: { tenYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: []
    };

    let qrisk3Payload = {};
    qrisk3Payload.dataKey = 'qrisk3';
    qrisk3Payload.data = qrisk3Data;
    await this.props.dispatch(patient_actions.save_risk_results(qrisk3Payload));
    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
      deep_clone['qrisk3'] = qrisk3Data;
      await this.props.getPedigreeData().setRiskResults(deep_clone)
    }
  }

  async runRisk() {
    // set state to show loading backdrop

    const risk_settings = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_settings : this.props.patient.risk_settings;

    let risk_setup = {
      requiredData: [],
      fatalTerminations: {
        boadicea: { terminate: false, terminations: [] },
        claus: { terminate: false, terminations: [] },
        gail: { terminate: false, terminations: [] },
        tyrer_cuzick: { terminate: false, terminations: [] },
        premm: { terminate: false, terminations: [] },
        brcapro: { terminate: false, terminations: [] },
        mmrpro: { terminate: false, terminations: [] },
        pancpro: { terminate: false, terminations: [] },
        melapro: { terminate: false, terminations: [] },
        qrisk3: { terminate: false, terminations: [] }
      } // all fatal terminations pertain to the proband at this time
    };

    if (risk_settings.boadicea) {
      await this.validateBoadiceaData(risk_setup);
    }

    if (risk_settings.claus) {
      await this.validateClausData(risk_setup);
    }

    if (risk_settings.gail) {
      await this.validateGailData(risk_setup);
    }

    if (risk_settings.tyrer_cuzick) {
      await this.validateTyrerCuzickData(risk_setup);
    }

    this.setState({ risk_errors: risk_setup });

    const boadiceaFatalTermination = risk_setup.fatalTerminations.boadicea.terminate
    const clausFatalTermination = risk_setup.fatalTerminations.claus.terminate
    const gailFatalTerminations = risk_setup.fatalTerminations.gail.terminate
    const tyrerFatalTerminations = risk_setup.fatalTerminations.tyrer_cuzick.terminate
    const brcaproFatalTerminations = risk_setup.fatalTerminations.brcapro.terminate
    const mmrproFatalTerminations = risk_setup.fatalTerminations.mmrpro.terminate
    const pancproFatalTerminations = risk_setup.fatalTerminations.pancpro.terminate
    const melaproFatalTerminations = risk_setup.fatalTerminations.melapro.terminate
    const qrisk3FatalTerminations = risk_setup.fatalTerminations.qrisk3.terminate

    if(risk_setup.requiredData.length > 0 || (boadiceaFatalTermination || clausFatalTermination || gailFatalTerminations || tyrerFatalTerminations || brcaproFatalTerminations || mmrproFatalTerminations || pancproFatalTerminations || melaproFatalTerminations || qrisk3FatalTerminations)){
      this.setState({ showMissingDataModal: true })
    } else {
      this.calculateRisk();
    }
  }

  stopRiskCalculation() {
    this.setState({
      runningRiskCalculation: false,
      runningBoadicea: false,
      runningClaus: false,
      runningGail: false,
      runningTyrerCuzick: false,
      runningBRCAPRO: false,
      runningMELAPRO: false,
      runningPANCPRO: false,
      runningMMRPRO: false,
      runningQRISK3: false,
    })
  }

  boadiceaCallback(boadicea_risk_errors) {
    const stopSpinner = (!this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      boadiceaRuntimeErrors: boadicea_risk_errors['errors'],
      boadiceaRuntimeWarnings: boadicea_risk_errors['warnings'] ? boadicea_risk_errors['warnings'] : [],
      runningBoadicea: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  clausCallback(claus_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningGail &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO && 
                          !this.state.runningQRISK3
                        );
    this.setState({
      clausRuntimeErrors: claus_risk_error,
      runningClaus: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  gailCallback(gail_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      gailRuntimeErrors: gail_risk_error,
      runningGail: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  tyrercuzickCallback(tyrer_cuzick_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      tyrerCuzickRuntimeErrors: tyrer_cuzick_risk_error,
      runningTyrerCuzick: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  brcaproCallback(brcapro_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      brcaproRuntimeErrors: brcapro_risk_error['errors'],
      brcaproRuntimeWarnings: brcapro_risk_error['warnings'],
      runningBRCAPRO: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  mmrproCallback(mmrpro_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      mmrproRuntimeErrors: mmrpro_risk_error['errors'],
      mmrproRuntimeWarnings: mmrpro_risk_error['warnings'],
      runningMMRPRO: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  melaproCallback(melapro_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningPANCPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      melaproRuntimeErrors: melapro_risk_error['errors'],
      melaproRuntimeWarnings: melapro_risk_error['warnings'],
      runningMELAPRO: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  qrisk3Callback(qrisk3_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningPANCPRO
                        );

    this.setState({
      qrisk3RuntimeErrors: qrisk3_risk_error['errors'],
      qrisk3RuntimeWarnings: qrisk3_risk_error['warnings'],
      runningQRISK3: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  pancproCallback(pancpro_risk_error) {
    const stopSpinner = (!this.state.runningBoadicea &&
                          !this.state.runningClaus &&
                          !this.state.runningGail &&
                          !this.state.runningMMRPRO &&
                          !this.state.runningTyrerCuzick &&
                          !this.state.runningMELAPRO &&
                          !this.state.runningBRCAPRO &&
                          !this.state.runningQRISK3
                        );
    this.setState({
      pancproRuntimeErrors: pancpro_risk_error['errors'],
      pancproRuntimeWarnings: pancpro_risk_error['warnings'],
      runningPANCPRO: false,
      runningRiskCalculation: (stopSpinner) ? false : true
    });
  }

  calculateRisk(){
    if (this.props.patient.risk_settings) {
      let proband_patient = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient
      const riskRunTimeStamp = this.createTimeStamp()

      const risk_settings = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_settings : this.props.patient.risk_settings;

      if (risk_settings.boadicea) {
        this.setState({runningBoadicea: true, runningRiskCalculation: true});
        const p = {
          dispatch: this.props.dispatch, 
          proband_id: proband_patient.id, 
          getPedigreeData: this.props.getPedigreeData, 
          timeStamp: riskRunTimeStamp,
          callback: this.boadiceaCallback
        }
        helper_risk.runBoadicea(p);
      } else if (risk_settings.boadicea == false) {
        let data = {
          breast: { fiveYear: 'N/A', lifetime: 'N/A' },
          ovarian: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: [],
          population_cancer_risks: [],
          mutation_probabilities: [
            { "no mutation": { decimal: "", percent: "N/A" } },
            { BRCA1: { decimal: "", percent: "N/A" } },
            { BRCA2: { decimal: "", percent: "N/A" } },
            { PALB2: { decimal: "", percent: "N/A" } },
            { CHEK2: { decimal: "", percent: "N/A" } },
            { ATM: { decimal: "", percent: "N/A" } },
            { BARD1: {decimal: "", percent: "N/A"} },
            { RAD51D: {decimal: "", percent: "N/A"} },
            { RAD51C: {decimal: "", percent: "N/A"} },
            { BRIP1: {decimal: "", percent: "N/A"} }
          ],
          nice_risk_results: []
        };

        let payload = {};
        payload.dataKey = 'boadicea';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['boadicea'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.claus) {
        this.setState({runningClaus: true, runningRiskCalculation: true});
        helper_risk.runClaus(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.clausCallback);
      } else if (risk_settings.claus == false) {
        let data = {
          breast: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: []
        };

        let payload = {};
        payload.dataKey = 'claus';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['claus'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.gail) {
        this.setState({runningGail: true, runningRiskCalculation: true});
        helper_risk.runGail(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.gailCallback);
      } else if (risk_settings.gail == false) {
        let data = {
          breast: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: []
        };

        let payload = {};
        payload.dataKey = 'gail';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['gail'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.tyrer_cuzick) {
        this.setState({runningTyrerCuzick: true, runningRiskCalculation: true});
        helper_risk.runTyrerCuzick(this.props.dispatch, proband_patient.id, risk_settings.competing_mortality, this.props.getPedigreeData, riskRunTimeStamp, this.tyrercuzickCallback);
      } else if (risk_settings.tyrer_cuzick == false) {
        let data = {
          breast: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: [],
          mutation_probabilities: [
            { "no mutation": { decimal: "", percent: "N/A" } },
            { BRCA1: { decimal: "", percent: "N/A" } },
            { BRCA2: { decimal: "", percent: "N/A" } }
          ]
        };

        let payload = {};
        payload.dataKey = 'tyrer_cuzick';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['tyrer_cuzick'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.brcapro) {
        this.setState({runningBRCAPRO: true, runningRiskCalculation: true});
        helper_risk.runBayesMendelBrcaPro(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.brcaproCallback);
      } else if (risk_settings.brcapro == false) {
        let data = {
          breast: { fiveYear: 'N/A', lifetime: 'N/A' },
          ovarian: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: [],
          mutation_probabilities: [
            { "no mutation": { decimal: "", percent: "N/A" } },
            { BRCA1: { decimal: "", percent: "N/A" } },
            { BRCA2: { decimal: "", percent: "N/A" } }
          ]
        };

        let payload = {};
        payload.dataKey = 'brcapro';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['brcapro'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.mmrpro) {
        this.setState({runningMMRPRO: true, runningRiskCalculation: true});
        helper_risk.runBayesMendelMmrPro(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.mmrproCallback);
      } else if (risk_settings.mmrpro == false) {
        let data = {
          colon: { fiveYear: 'N/A', lifetime: 'N/A' },
          uterine_endometrial: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: [],
          mutation_probabilities: [
            { "no mutation": { decimal: "", percent: "N/A" } },
            { MLH1: { decimal: "", percent: "N/A" } },
            { MSH2: { decimal: "", percent: "N/A" } },
            { MSH6: { decimal: "", percent: "N/A" } }
          ]
        };

        let payload = {};
        payload.dataKey = 'mmrpro';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['mmrpro'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.pancpro) {
        this.setState({runningPANCPRO: true, runningRiskCalculation: true});
        helper_risk.runBayesMendelPancPro(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.pancproCallback);
      } else if (risk_settings.pancpro == false) {
        let data = {
          pancreatic: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: [],
          mutation_probabilities: [
            { "no mutation": { decimal: "", percent: "N/A" } },
            { pancreaticGene: { decimal: "", percent: "N/A" } }
          ]
        };

        let payload = {};
        payload.dataKey = 'pancpro';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['pancpro'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.melapro) {
        this.setState({runningMELAPRO: true, runningRiskCalculation: true});
        helper_risk.runBayesMendelMelaPro(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.melaproCallback);
      } else if (risk_settings.melapro == false) {
        let data = {
          melanoma: { fiveYear: 'N/A', lifetime: 'N/A' },
          cancer_risks: [],
          mutation_probabilities: [
            { "no mutation": { decimal: "", percent: "N/A" } },
            { P16: { decimal: "", percent: "N/A" } }
          ]
        };

        let payload = {};
        payload.dataKey = 'melapro';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['melapro'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }

      if (risk_settings.qrisk3) {
        this.setState({runningQRISK3: true, runningRiskCalculation: true});
        helper_risk.runQRisk3(this.props.dispatch, proband_patient.id, this.props.getPedigreeData, riskRunTimeStamp, this.qrisk3Callback);
      } else if (risk_settings.qrisk3 == false) {
        let data = {
          cardiovascular: { tenYear: 'N/A' },
          cancer_risks: []
        };

        let payload = {};
        payload.dataKey = 'qrisk3';
        payload.data = data;
        this.props.dispatch(patient_actions.save_risk_results(payload));

        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let deep_clone = cloneDeep(this.props.getPedigreeData().risk_results);
          deep_clone['qrisk3'] = data;
          this.props.getPedigreeData().setRiskResults(deep_clone)
        }
      }
    }
  }

  getProfileLabels() {
    let proband = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband : this.props.patient.patient
    let probandFirstName = proband.first_name;
    let probandLastName = proband.last_name;
    let probandId = proband.patient_id;
    let probandDob = proband.dob;
    let probandAge = proband.age;
    let probandGender = proband.gender;

    let payload = {
      probandFirstName,
      probandLastName,
      probandId,
      probandDob,
      probandAge,
      probandGender
    }

    if (payload.probandFirstName == null) payload.probandFirstName = ''
    if (payload.probandLastName == null) payload.probandLastName = ''

    if (payload.probandDob == null) {
      payload.probandDob = ''
    }

    if (payload.probandAge == null) {
      payload.probandAge = ''
    }

    if (payload.probandGender == 'm'){
      payload.probandGender = ', Male';
    }
    else if (payload.probandGender == 'f'){
      payload.probandGender = ', Female';
    }
    else{
      payload.probandGender = '';
    }

    //convert DOB based on local date format
    if(payload.probandDob){
      let dob = new Date(probandDob)
      dob = new Date( dob.getTime() + Math.abs(dob.getTimezoneOffset()*60000) )
      let optionsDob = { year: 'numeric', month: 'numeric', day: 'numeric' };
      dob = dob.toLocaleDateString(undefined, optionsDob)
      payload.probandDob = dob;
    }


    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    switch(mm){
      case '01':
        mm = 'January';
        break;
      case '02':
        mm = 'February';
        break;
      case '03':
        mm = 'March';
        break;
      case '04':
        mm = 'April';
        break;
      case '05':
        mm = 'May';
        break;
      case '06':
        mm = 'June';
        break;
      case '07':
        mm = 'July';
        break;
      case '08':
        mm = 'August';
        break;
      case '09':
        mm = 'September';
        break;
      case '10':
        mm = 'October';
        break;
      case '11':
        mm = 'November';
        break;
      case '12':
        mm = 'December';
        break;
    }

    today = mm + ' ' + dd + ', ' + yyyy;
    //convert date today based on local date format
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    today = new Date(today).toLocaleDateString(undefined, options)

    payload.today = today;

    return payload;
  }

  async refreshRiskAssessmentCharts() {
    this.setState({ showRiskAssessmentCharts: false, showRiskTables: false })
    await helpers.delay(0.025);
    this.setState({ showRiskAssessmentCharts: true, showRiskTables: true })
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  // call this to Disable
  disableScroll() {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    window.onscroll = function () { window.scrollTo(0, 0); };
  }

  // call this to Enable
  enableScroll() {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    window.onscroll = function () { };
  }

  closeNoRiskResultsModal() {
    this.setState({ showNoRiskResultsModal: false });
  }

  createRiskModelColumnStringsArray(years) {;
    let RiskModelColumnStringsArray = []
    if (years === 'five-year') {
      RiskModelColumnStringsArray.push('5 Year', 'Lifetime')
    } else if (years === 'ten-year') {
      RiskModelColumnStringsArray.push('10 year', 'Lifetime')
    } else {
      RiskModelColumnStringsArray.push('5 Year', '10 Year', 'Lifetime')
    }
    return RiskModelColumnStringsArray
  }

  get5YearOr10YearRiskValues(){
    let proband_age = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband.age : this.props.patient.patient.age;
    let proband_age_after_10_years = Number(proband_age) + 10;
    function round(num, precision){
      return Number(Math.round(num + "e+" + precision) + "e-" + precision);
    }

    let risk_results = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_results : this.props.patient.risk_results;
    let risk_model_years = this.props.session.user.risk_model_years;

    //Some of the risk models have a max year, so first make sure the returned data includes a ten year value before trying to assign
    let boadiceaTenYearExists = (risk_results.boadicea.cancer_risks.length > 0 && risk_results.boadicea.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let clausTenYearExists = (risk_results.claus.cancer_risks.length > 0 && risk_results.claus.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let gailTenYearExists = (risk_results.gail.cancer_risks.length > 0 && risk_results.gail.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let brcaproTenYearExists = (risk_results.brcapro.cancer_risks.length > 0 && risk_results.brcapro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let tyrerTenYearExists = (risk_results.tyrer_cuzick.cancer_risks.length > 0 && risk_results.tyrer_cuzick.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let mmrproTenYearExists = (risk_results.mmrpro.cancer_risks.length > 0 && risk_results.mmrpro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let melaproTenYearExists = (risk_results.melapro.cancer_risks.length > 0 && risk_results.melapro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let pancproTenYearExists = (risk_results.pancpro.cancer_risks.length > 0 && risk_results.pancpro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years))
    let qrisk3TenYearExists = (risk_results.qrisk3.cancer_risks.length > 0 && risk_results.qrisk3.cardiovascular.tenYear !== undefined && risk_results.qrisk3.cardiovascular.tenYear !== null)
    //let qrisk3LifetimeExists = (risk_results.qrisk3.cancer_risks.length > 0 && risk_results.qrisk3.cardiovascular.lifetime !== undefined && risk_results.qrisk3.cardiovascular.lifetime !== null)

    //get 10 year risks
    let boadicea_ten_year_breast_risk = boadiceaTenYearExists ? round(risk_results.boadicea.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years)['breast cancer risk'].percent, 2) : 'N/A'
    let boadicea_ten_year_ovarian_risk = boadiceaTenYearExists ? round(risk_results.boadicea.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years)['ovarian cancer risk'].percent, 2) : 'N/A'

    let claus_ten_year_breast_risk = clausTenYearExists ? round(risk_results.claus.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).breast, 2) : 'N/A'

    let gail_ten_year_breast_risk = gailTenYearExists ? round(risk_results.gail.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).risk_absolute, 2) : 'N/A'

    //tyrer cuzick has no percentage value so we convert decimal value to percentage
    let tyrer_cuzick_ten_year_breast_risk = tyrerTenYearExists ? (100.0 * parseFloat(risk_results.tyrer_cuzick.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).breast.decimal)).toFixed(2) : 'N/A'

    let brcapro_ten_year_breast_risk = brcaproTenYearExists ? round(risk_results.brcapro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).breast.percent, 2) : 'N/A'
    let brcapro_ten_year_ovarian_risk = brcaproTenYearExists ? round(risk_results.brcapro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).ovarian.percent, 2) : 'N/A'

    let mmrpro_ten_year_colon_risk = mmrproTenYearExists ? round(risk_results.mmrpro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).colon.percent, 2) : 'N/A'
    let mmrpro_ten_year_uterine_endometrial_risk = mmrproTenYearExists ? round(risk_results.mmrpro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).uterine_endometrial.percent, 2) : 'N/A'

    let pancpro_ten_year_pancreatic_risk = pancproTenYearExists ? round(risk_results.pancpro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).pancreatic.percent, 2) : 'N/A'

    let melapro_ten_year_melanoma_risk = melaproTenYearExists ? round(risk_results.melapro.cancer_risks.find(risk => Number(risk.age) === proband_age_after_10_years).melanoma.percent, 2) : 'N/A'

    let qrisk3_ten_year_cardiovascular_risk = qrisk3TenYearExists ? round(risk_results.qrisk3.cardiovascular.tenYear, 2) : 'N/A'
    let qrisk_average_value = risk_results.qrisk3.cardiovascular.average
    let qrisk_relative_value = risk_results.qrisk3.cardiovascular.relative
    // let qrisk3_lifetime_cardiovascular_risk = 'N/A'
    // if (qrisk_lifetime_value !== undefined && qrisk_lifetime_value !== 'N/A') {
    //   qrisk_lifetime_value = qrisk_lifetime_value.at(-1)["risk"]
    //   qrisk3_lifetime_cardiovascular_risk = qrisk3LifetimeExists ? round(qrisk_lifetime_value, 2) : 'N/A'
    // }

    let risk_model_values = {
      column_string_year_array: this.createRiskModelColumnStringsArray(risk_model_years),

      boadicea_breast_risk_five_year_value: (risk_model_years === 'five-year' && proband_age < 76 || risk_model_years === 'both' && proband_age < 76 ) ? String(risk_results.boadicea.breast.fiveYear) : null,
      boadicea_breast_risk_ten_year_value: (risk_model_years === 'ten-year' && proband_age < 76 || risk_model_years === 'both' && proband_age < 76 ) ? boadicea_ten_year_breast_risk : null,

      boadicea_ovarian_risk_five_year_value: (risk_model_years === 'five-year' && proband_age < 76 || risk_model_years === 'both' && proband_age < 76 ) ? String(risk_results.boadicea.ovarian.fiveYear) : null,
      boadicea_ovarian_risk_ten_year_value: (risk_model_years === 'ten-year' && proband_age < 76 || risk_model_years === 'both' && proband_age < 76 ) ? boadicea_ten_year_ovarian_risk : null,
      // boadicea_ovarian_risk_value: (risk_model_years === 'five-year' && proband_age < 76 ) ? String(risk_results.boadicea.ovarian.fiveYear) : boadicea_ten_year_ovarian_risk,

      claus_breast_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.claus.breast.fiveYear) : null,
      claus_breast_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? claus_ten_year_breast_risk : null,
      // claus_breast_risk_value: risk_model_years === 'five-year' ? String(risk_results.claus.breast.fiveYear) : claus_ten_year_breast_risk,

      gail_breast_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.gail.breast.fiveYear) : null,
      gail_breast_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? gail_ten_year_breast_risk : null,
      // gail_breast_risk_value: risk_model_years === 'five-year' ? String(risk_results.gail.breast.fiveYear) : gail_ten_year_breast_risk,

      tyrer_cuzick_breast_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.tyrer_cuzick.breast.fiveYear) : null,
      tyrer_cuzick_breast_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? tyrer_cuzick_ten_year_breast_risk : null,
      // tyrer_cuzick_breast_risk_value: risk_model_years === 'five-year' ? String(risk_results.tyrer_cuzick.breast.fiveYear) : tyrer_cuzick_ten_year_breast_risk,

      brcapro_breast_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.brcapro.breast.fiveYear) : null,
      brcapro_breast_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? brcapro_ten_year_breast_risk : null,
      // brcapro_breast_risk_value: risk_model_years === 'five-year' ? String(risk_results.brcapro.breast.fiveYear) : brcapro_ten_year_breast_risk,

      brcapro_ovarian_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.brcapro.ovarian.fiveYear) : null,
      brcapro_ovarian_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? brcapro_ten_year_ovarian_risk : null,
      // brcapro_ovarian_risk_value: risk_model_years === 'five-year' ? String(risk_results.brcapro.ovarian.fiveYear) : brcapro_ten_year_ovarian_risk,

      mmrpro_colon_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.mmrpro.colon.fiveYear) : null,
      mmrpro_colon_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? mmrpro_ten_year_colon_risk : null,
      // mmrpro_colon_risk_value: risk_model_years === 'five-year' ? String(risk_results.mmrpro.colon.fiveYear) : mmrpro_ten_year_colon_risk,

      mmrpro_uterine_endometrial_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.mmrpro.uterine_endometrial.fiveYear) : null,
      mmrpro_uterine_endometrial_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? mmrpro_ten_year_uterine_endometrial_risk : null,
      // mmrpro_uterine_endometrial_risk_value: risk_model_years === 'five-year' ? String(risk_results.mmrpro.uterine_endometrial.fiveYear) : mmrpro_ten_year_uterine_endometrial_risk,

      pancpro_pancreatic_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.pancpro.pancreatic.fiveYear) : null,
      pancpro_pancreatic_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? pancpro_ten_year_pancreatic_risk : null,
      // pancpro_pancreatic_risk_value: risk_model_years === 'five-year' ? String(risk_results.pancpro.pancreatic.fiveYear) : pancpro_ten_year_pancreatic_risk,

      melapro_melanoma_risk_five_year_value: (risk_model_years === 'five-year' || risk_model_years === 'both') ? String(risk_results.melapro.melanoma.fiveYear) : null,
      melapro_melanoma_risk_ten_year_value: (risk_model_years === 'ten-year' || risk_model_years === 'both' ) ? melapro_ten_year_melanoma_risk : null,
      // melapro_melanoma_risk_value: risk_model_years === 'five-year' ? String(risk_results.melapro.melanoma.fiveYear) : melapro_ten_year_melanoma_risk

      qrisk3_cardiovascular_risk_ten_year_value: qrisk3_ten_year_cardiovascular_risk,
      qrisk3_cardiovascular_risk_average_value: qrisk_average_value,
      qrisk3_cardiovascular_risk_relative_value: qrisk_relative_value
    }

    return risk_model_values;
  }

  handlePrintClick = async() => {
    this.setState({ runningRiskCalculation: true });
    let svgPages = []
    let startingX = 50
    let startingY = 110
    let startingChartX = 0
    let startingChartY = 0


    let risk_results = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_results : this.props.patient.risk_results;
    let risk_settings = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_settings : this.props.patient.risk_settings;

    //get tables that have data
    let has_breast_risk_table = false;
    let has_ovarian_risk_table = false;
    let has_colon_risk_table = false;
    let has_uterine_endometrial_risk_table = false;
    let has_pancreatic_risk_table = false;
    let has_melanoma_risk_table = false;
    let has_cardiovascular_risk_table = false;
    let has_mutation_risk_table = false;
    let has_mutation_risk_table_2 = false;

    //get graphs that have data
    let has_claus_breast_chart = false;
    let has_gail_breast_chart = false;
    let has_tyrer_cuzick_breast_chart = false;
    let has_brcapro_breast_chart = false;
    let has_brcapro_ovarian_chart = false;
    let has_mmrpro_colon_chart = false;
    let has_mmrpro_uterine_endometrial_chart = false;
    let has_pancpro_pancreatic_chart = false;
    let has_melapro_melanoma_chart = false;
    //let has_cardiovascular_risk_chart = false;
    let has_boadicea_breast_chart = false;
    let has_boadicea_ovarian_chart = false;

    let boadicea_run = risk_settings.boadicea && this.state.boadiceaRuntimeErrors.length === 0;
    let claus_run = risk_settings.claus && this.state.clausRuntimeErrors.length === 0;
    let gail_run = risk_settings.gail && this.state.gailRuntimeErrors.length === 0;
    let tyrer_cuzick_run = risk_settings.tyrer_cuzick && this.state.tyrerCuzickRuntimeErrors.length === 0;
    let brcapro_run = risk_settings.brcapro && this.state.brcaproRuntimeErrors.length === 0;
    let mmrpro_run = risk_settings.mmrpro && this.state.mmrproRuntimeErrors.length === 0;
    let pancpro_run = risk_settings.pancpro && this.state.pancproRuntimeErrors.length === 0;
    let melapro_run = risk_settings.melapro && this.state.melaproRuntimeErrors.length === 0;
    let qrisk3_run = risk_settings.qrisk3 && this.state.qrisk3RuntimeErrors.length === 0;


    let all_chart_count = 0;
    let all_table_count = 0;

    let hasContent = false;


    if (boadicea_run) {
      has_boadicea_breast_chart = true;
      has_boadicea_ovarian_chart = true;
      has_breast_risk_table = true;
      has_ovarian_risk_table = true;
      has_mutation_risk_table = true;
      hasContent = true;
      all_chart_count += 2;
    }
    if (claus_run) {
      has_claus_breast_chart = true;
      has_breast_risk_table = true;
      hasContent = true;
      all_chart_count += 1;
    }
    if (gail_run) {
      has_gail_breast_chart = true;
      has_breast_risk_table = true;
      hasContent = true;
      all_chart_count += 1;
    }
    if (tyrer_cuzick_run) {
      has_tyrer_cuzick_breast_chart = true;
      has_breast_risk_table = true;
      has_mutation_risk_table = true;
      hasContent = true;
      all_chart_count += 1;
    }
    if (brcapro_run) {
      has_brcapro_breast_chart = true;
      has_brcapro_ovarian_chart = true;
      has_breast_risk_table = true;
      has_ovarian_risk_table = true;
      has_mutation_risk_table = true;
      hasContent = true;
      all_chart_count += 2;
    }
    if (mmrpro_run) {
      has_mmrpro_colon_chart = true;
      has_mmrpro_uterine_endometrial_chart = true;
      has_colon_risk_table = true;
      has_uterine_endometrial_risk_table = true;
      if(risk_results.mmrpro.mutation_probabilities[1].MLH1.percent != 'N/A' ||
        risk_results.mmrpro.mutation_probabilities[2].MSH2.percent != 'N/A' ||
        risk_results.mmrpro.mutation_probabilities[3].MSH6.percent != 'N/A'){
          has_mutation_risk_table_2 = true;
        }
      hasContent = true;
      all_chart_count += 2;
    }
    if (pancpro_run) {
      has_pancpro_pancreatic_chart = true;
      has_pancreatic_risk_table = true;
      if(risk_results.pancpro.mutation_probabilities[1].pancreaticGene.percent != 'N/A'){
        has_mutation_risk_table_2 = true;
      }
      hasContent = true;
      all_chart_count += 1;
    }
    if (melapro_run) {
      has_melapro_melanoma_chart = true;
      has_melanoma_risk_table = true;
      if(risk_results.melapro.mutation_probabilities[1].P16.percent != 'N/A'){
        has_mutation_risk_table_2 = true;
      }
      hasContent = true;
      all_chart_count += 1;
    }
    if (qrisk3_run) {
      //has_cardiovascular_risk_chart = true;
      has_cardiovascular_risk_table = true;
      hasContent = true;
      all_table_count += 1;
    }

    if (!hasContent){
      this.setState({ showNoRiskResultsModal: true });
      return;
    }
    let breast_risk_table = document.getElementById("breast-risk-table");
    let ovarian_risk_table = document.getElementById("ovarian-risk-table");
    let colon_risk_table = document.getElementById("colon-risk-table");
    let uterine_endometrial_risk_table = document.getElementById("uterine-endometrial-risk-table");
    let pancreatic_risk_table = document.getElementById("pancreatic-risk-table");
    let melanoma_risk_table = document.getElementById("melanoma-risk-table");
    let cardiovascular_risk_table = document.getElementById("cardiovascular-risk-table");
    let mutation_risk_table = document.getElementById("mutation-risk-table");
    let mutation_risk_table_2 = document.getElementById("mutation-risk-table-2");

    let boadicea_tab_content_breast = document.getElementById("boadicea-tab-content-breast");
    let boadicea_tab_content_ovarian = document.getElementById("boadicea-tab-content-ovarian");
    let claus_tab_content = document.getElementById("claus-tab-content");
    let gail_tab_content = document.getElementById("gail-tab-content");
    let tyrer_cuzick_tab_content = document.getElementById("tyrer-cuzick-tab-content");
    let brcapro_tab_content_breast = document.getElementById("brcapro-tab-content-breast");
    let brcapro_tab_content_ovarian = document.getElementById("brcapro-tab-content-ovarian");
    let mmrpro_tab_content_colon = document.getElementById("mmrpro-tab-content-colon");
    let mmrpro_tab_content_uterine_endometrial = document.getElementById("mmrpro-tab-content-uterine-endometrial");
    let pancpro_tab_content = document.getElementById("pancpro-tab-content");
    let melapro_tab_content = document.getElementById("melapro-tab-content");
    let qrisk3_tab_content = document.getElementById("qrisk3-tab-content");
    await this.riskAssessmentChartsRef.current.showAllRiskAssessmentsForPrint(risk_settings, this.state);

    await helpers_risk_model_print.prepareForExport(breast_risk_table, ovarian_risk_table, colon_risk_table, uterine_endometrial_risk_table,
      pancreatic_risk_table, melanoma_risk_table, cardiovascular_risk_table, mutation_risk_table, mutation_risk_table_2, boadicea_tab_content_breast,
      boadicea_tab_content_ovarian, claus_tab_content, gail_tab_content, tyrer_cuzick_tab_content, brcapro_tab_content_breast,
      brcapro_tab_content_ovarian, mmrpro_tab_content_colon, mmrpro_tab_content_uterine_endometrial, pancpro_tab_content,
      melapro_tab_content, qrisk3_tab_content
      );

    await window.scrollTo(0, 0);
    this.disableScroll();
    await helpers.delay(.6)

    var allTablesCount = [has_breast_risk_table,
       has_ovarian_risk_table,
       has_colon_risk_table,
       has_uterine_endometrial_risk_table,
       has_pancreatic_risk_table,
       has_melanoma_risk_table,
       has_cardiovascular_risk_table,
       has_mutation_risk_table,
       has_mutation_risk_table_2];

    allTablesCount = allTablesCount.filter(Boolean).length;

    //for patient detail labels
    let profileLabels = this.getProfileLabels();
    let risk_model_values = this.get5YearOr10YearRiskValues();
    let risk_model_years = this.props.session.user.risk_model_years;

    let header = helpers_risk_model_print.createGraphHeader(profileLabels)
    let svgTablePage1 = helpers_risk_model_print.createSvgContainer()
    let svgTablePage2 = helpers_risk_model_print.createSvgContainer()



    let svgPage1 = helpers_risk_model_print.createSvgContainer()
    let svgPage2 = helpers_risk_model_print.createSvgContainer()
    let svgPage3 = helpers_risk_model_print.createSvgContainer()
    let svgPage4 = helpers_risk_model_print.createSvgContainer()

    svgPage1.appendChild(header.cloneNode(true))
    svgPage2.appendChild(header.cloneNode(true))
    svgPage3.appendChild(header.cloneNode(true))
    svgPage4.appendChild(header.cloneNode(true))

    let tableCounter = 0;
    let currentPage = 1;
    let chartCount = 0
    let totalChartCount = 0
    let chartStartingX = 60
    let chartStartingY = 140
    let mutationStartingX = 60
    let mutationStartingY = 140

    let versionsText = 'Model Versions: ';
    if(boadicea_run){
      versionsText += 'BOADICEA 6.3.0, Ovarian 2.2.4, ';
    }
    if(claus_run){
      versionsText += 'Claus et al. (1993), Claus et al. (1994), ';
    }
    if(gail_run){
      versionsText += 'Gail 4, ';
    }
    if(tyrer_cuzick_run){
      versionsText += 'Tyrer-Cuzick 8, '
    }
    if(brcapro_run || mmrpro_run || pancpro_run || melapro_run){
      versionsText += 'BayesMendel 2.1-8, '
    }

    versionsText = versionsText.slice(0, -2)
    let svgFooter = helpers_risk_model_print.createFooter(versionsText)

    //draw any risk tables with data
  if(allTablesCount > 0){
    svgTablePage1.appendChild(header.cloneNode(true))
    svgTablePage1.appendChild(svgFooter)

    if(has_breast_risk_table){
      let riskTables = helpers_risk_model_print.createBreastRiskTable(risk_model_values, risk_results, risk_settings, chartStartingX, chartStartingY, boadicea_run, claus_run, gail_run, tyrer_cuzick_run, brcapro_run, risk_model_years)
      tableCounter +=1
      chartStartingY = riskTables['yEnd']
      svgTablePage1.appendChild(riskTables['tableContainer'])
    }
    if(has_ovarian_risk_table){
      let ovarianY = tableCounter >=1 ? chartStartingY + 50 : chartStartingY
      let ovarianTable = helpers_risk_model_print.createOvarianTable(risk_model_values, risk_results, risk_settings, chartStartingX, ovarianY, boadicea_run, brcapro_run, risk_model_years)
      tableCounter +=1
      chartStartingY = ovarianTable['yEnd']
      svgTablePage1.appendChild(ovarianTable['tableContainer'])
    }
    if(has_colon_risk_table){
      let colonY = tableCounter >=1 ? chartStartingY + 50 : chartStartingY
      let colonTable = helpers_risk_model_print.createColonTable(risk_model_values, risk_results, risk_settings, chartStartingX, colonY, mmrpro_run, risk_model_years)
      tableCounter +=1
      chartStartingY = colonTable['yEnd']
      svgTablePage1.appendChild(colonTable['tableContainer'])

    }
    if(has_uterine_endometrial_risk_table){
      let uterinY = tableCounter >=1 ? chartStartingY + 50 : chartStartingY
      let uterineTable = helpers_risk_model_print.createUterineTable(risk_model_values, risk_results, risk_settings, chartStartingX, uterinY, mmrpro_run, risk_model_years)
      tableCounter +=1
      chartStartingY = uterineTable['yEnd']
      svgTablePage1.appendChild(uterineTable['tableContainer'])

    }
    if(has_pancreatic_risk_table){
      let pancY = tableCounter >=1 ? chartStartingY + 50 : chartStartingY
      let pancTable = helpers_risk_model_print.createPancTable(risk_model_values, risk_results, risk_settings, chartStartingX, pancY, pancpro_run, risk_model_years)
      tableCounter +=1
      chartStartingY = pancTable['yEnd']
      svgTablePage1.appendChild(pancTable['tableContainer'])

    }
    if(has_melanoma_risk_table){
      let melanomaY = tableCounter >=1 ? chartStartingY + 50 : chartStartingY
      let melanomaTable = helpers_risk_model_print.createMelanomaTable(risk_model_values, risk_results, risk_settings, chartStartingX, melanomaY, melapro_run, risk_model_years)
      tableCounter +=1
      chartStartingY = melanomaTable['yEnd']
      svgTablePage1.appendChild(melanomaTable['tableContainer'])

    }
    if (has_cardiovascular_risk_table) {
      let cardiovascularY = tableCounter >=1 ? chartStartingY + 50 : chartStartingY
      let cardiovascularTable = helpers_risk_model_print.createCardiovascularTable(risk_model_values, risk_results, risk_settings, chartStartingX, cardiovascularY, qrisk3_run, risk_model_years)
      tableCounter +=1
      chartStartingY = cardiovascularTable['yEnd']
      svgTablePage1.appendChild(cardiovascularTable['tableContainer'])
    }
    svgPages.push(new XMLSerializer().serializeToString(svgTablePage1))
  }

  //draw mutation tables, if any
if(has_mutation_risk_table || has_mutation_risk_table_2) {
  svgTablePage2.appendChild(header.cloneNode(true))
  let headerX = 50
  var title = helpers_risk_model_print.createChartHeader('Mutation', headerX, mutationStartingY, '20', '#0072CE')
  mutationStartingY+= 25
  svgTablePage2.appendChild(title)

  if(has_mutation_risk_table){
    let mutationtable1 = helpers_risk_model_print.createMutationTable1(risk_results, risk_settings, mutationStartingX, mutationStartingY, boadicea_run, tyrer_cuzick_run, brcapro_run, risk_model_years)
    mutationStartingY = mutationtable1['yEnd']
    svgTablePage2.appendChild(mutationtable1['tableContainer'])
  }
  if(has_mutation_risk_table_2){
    let table2y = has_mutation_risk_table ? mutationStartingY += 50 : mutationStartingY
    let mutationTable2 = helpers_risk_model_print.createMutationTable2(risk_results, risk_settings, mutationStartingX, table2y, mmrpro_run, pancpro_run, melapro_run, risk_model_years)
    mutationStartingY = mutationTable2['yEnd']

    svgTablePage2.appendChild(mutationTable2['tableContainer'])

  }
  svgPages.push(new XMLSerializer().serializeToString(svgTablePage2))

}



  //draw graphs
    if (has_boadicea_breast_chart) {
      let boadicea_graph = document.getElementById("apexchartsbreast-chart").firstChild.cloneNode(true);
      let svg_boadicea =  helpers_risk_model_print.generateSvgGraph(boadicea_graph, startingX, startingY, 'BOADICEA - Breast', "Breast", "Population Breast Cancer Risk", risk_model_years)
      startingY += 275
      chartCount += 1
      totalChartCount +=1
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_boadicea, currentPage)

  }
    if (has_boadicea_ovarian_chart) {
      let ovarian_graph = document.getElementById("apexchartsovarian-chart").firstChild.cloneNode(true);
      let svg_ovarian = helpers_risk_model_print.generateSvgGraph(ovarian_graph, startingX, startingY, 'BOADICEA - Ovarian', "Ovarian", "Population Ovarian Cancer Risk", risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_ovarian, currentPage)
    }

    if (has_claus_breast_chart) {
      let claus_graph = document.getElementById("apexchartsclaus-breast-chart").firstChild.cloneNode(true);
      let svg_claus = helpers_risk_model_print.generateSvgGraph(claus_graph, startingX, startingY,"Claus - Breast" , "Breast", null, risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_claus, currentPage)

    }

    if (has_gail_breast_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let gail_graph = document.getElementById("apexchartsgail-breast-chart").firstChild.cloneNode(true);
      let svg_gail = helpers_risk_model_print.generateSvgGraph(gail_graph, startingX, startingY, "Gail - Breast", "Breast", "Population Breast Cancer Risk", risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_gail, currentPage)

    }

    if (has_tyrer_cuzick_breast_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      console.log(document.getElementById("apexchartstyrer-cuzick-breast-chart"))
      let tyrer_graph = document.getElementById("apexchartstyrer-cuzick-breast-chart").firstChild.cloneNode(true);
      let svg_tyrer = helpers_risk_model_print.generateSvgGraph(tyrer_graph, startingX, startingY, 'Tyrer-Cuzick - Breast', "Breast", "Population Breast Cancer Risk", risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_tyrer, currentPage)

    }
    if (has_brcapro_breast_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let brcapro_breast = document.getElementById("apexchartsbrcapro-breast-chart").firstChild.cloneNode(true);
      let svg_brcapro_breast = helpers_risk_model_print.generateSvgGraph(brcapro_breast, startingX, startingY, "BRCAPRO - Breast", "Breast", null, risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_brcapro_breast, currentPage)

    }


    if (has_brcapro_ovarian_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let brcapro_ovarian = document.getElementById("apexchartsbrcapro-ovarian-chart").firstChild.cloneNode(true);
      let svg_brcapro_ovarian = helpers_risk_model_print.generateSvgGraph(brcapro_ovarian, startingX, startingY, "BRCAPRO - Ovarian", "Ovarian", null, risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_brcapro_ovarian, currentPage)
    }

    if (has_mmrpro_colon_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let mmrpro_colon_chart = document.getElementById("apexchartscolon-chart").firstChild.cloneNode(true);
      let svg_colon_mmrpro = helpers_risk_model_print.generateSvgGraph(mmrpro_colon_chart, startingX, startingY, "MMRPRO - Colon", "Colon", null, risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_colon_mmrpro, currentPage)
    }

    if (has_mmrpro_uterine_endometrial_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let mmrpro_uterine_chart = document.getElementById("apexchartsuterine-endometrial-chart").firstChild.cloneNode(true);
      let svg_uterine = helpers_risk_model_print.generateSvgGraph(mmrpro_uterine_chart, startingX, startingY, "MMRPRO - Uterine/Endometrial", "Uterine/Endometrial", null, risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_uterine, currentPage)

    }

    if (has_pancpro_pancreatic_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let pancpro_chart = document.getElementById("apexchartspancreatic-chart").firstChild.cloneNode(true);
      let svg_pancpro = helpers_risk_model_print.generateSvgGraph(pancpro_chart, startingX, startingY, "PANCPRO - Pancreatic", "Pancreatic", null, risk_model_years)
      chartCount += 1
      totalChartCount +=1
      startingY+=275
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_pancpro, currentPage)
    }


    if (has_melapro_melanoma_chart) {
      if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
        startingY = 110
        chartCount = 0
        currentPage += 1
      }
      let mela_chart = document.getElementById("apexchartsmelanoma-chart").firstChild.cloneNode(true);
      let svg_mela = helpers_risk_model_print.generateSvgGraph(mela_chart, startingX, startingY, "MELAPRO - Melanoma", "Melanoma", null, risk_model_years)
      chartCount += 1
      startingY+=275
      totalChartCount +=1
      this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_mela, currentPage)

    }

    // if (has_cardiovascular_risk_chart) {
    //   if(this.newPageCheck(chartCount, totalChartCount, all_chart_count)){
    //       startingY = 110
    //       chartCount = 0
    //       currentPage += 1
    //   }
    //   let qrisk3_chart = document.getElementById("apexchartsqrisk3-chart").firstChild.cloneNode(true);
    //   let svg_qrisk3 = helpers_risk_model_print.generateSvgGraph(qrisk3_chart, startingX, startingY, "QRISK3 - Cardiovascular", "Cardiovascular", null, risk_model_years)
    //   chartCount += 1
    //   startingY+=275
    //   totalChartCount +=1
    //   this.addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svg_qrisk3, currentPage)
    // }

    if(currentPage == 1) {
      svgPages.push(new XMLSerializer().serializeToString(svgPage1))
    }else if(currentPage == 2){
      svgPages.push(new XMLSerializer().serializeToString(svgPage1))
      svgPages.push(new XMLSerializer().serializeToString(svgPage2))
      }else if(currentPage == 3){
        svgPages.push(new XMLSerializer().serializeToString(svgPage1))
        svgPages.push(new XMLSerializer().serializeToString(svgPage2))
        svgPages.push(new XMLSerializer().serializeToString(svgPage3))
      }
      else if(currentPage == 4){
        svgPages.push(new XMLSerializer().serializeToString(svgPage1))
        svgPages.push(new XMLSerializer().serializeToString(svgPage2))
        svgPages.push(new XMLSerializer().serializeToString(svgPage3))
        svgPages.push(new XMLSerializer().serializeToString(svgPage4))
      }


    let printPayload ={
      svgPages: svgPages
    }

    try {
      let data = await family_api.flux_risk_pdf_generator(printPayload)
      const blob = this.createBlob(data, 'application/pdf')
      const blubUrl = URL.createObjectURL(blob)
      var downloadLink = document.createElement("a");
      downloadLink.href = blubUrl;
      downloadLink.download = `risk-results.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch(err) {
      console.dir(err)
    }

    this.enableScroll();
    await this.refreshRiskAssessmentCharts();
    this.setState({ runningRiskCalculation: false })
  }

  addTableToSvg(svgPage1, svgPage2, svgPage3, svgPage4, svgChart, currentPage){
    if(currentPage == 1) {
      svgPage1.appendChild(svgChart)
    }else if(currentPage == 2){
      svgPage2.appendChild(svgChart)
    }else if(currentPage == 3){
      svgPage3.appendChild(svgChart)
    }else if(currentPage == 4){
      svgPage4.appendChild(svgChart)
    }
  }

  newPageCheck(chartCount, totalChartCount, allChartCount){
    if(chartCount > 0 && chartCount % 3 == 0 && totalChartCount !== allChartCount){
      return true
    }
    return false
  }

  async handleBoadiceaData() {
    try {
      const proband_id = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().proband.id : this.props.patient.patient.id;
      const data = await risk_api.get_boadicea_data({proband_id: proband_id});

      const file = new Blob([data], {type: 'text/plain'});
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = "boadicea-data.txt";
      document.body.appendChild(downloadLink); // Required for this to work in FireFox
      downloadLink.click();
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    // for (var key in this.state.boadiceaRuntimeErrors) {
    //   let list_item = (
    //     <div key={createUUID()} className="alert alert-danger" role="alert">
    //       <li key={key}>
    //         BOADICEA: {this.state.boadiceaRuntimeErrors[key]}
    //       </li>
    //     </div>
    //   );
    //   boadicea_errors.push(list_item);
    // }

    const boadicea_errors = this.state.boadiceaRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            BOADICEA: {error}
          </li>
        </div>
      );
    });

    const claus_errors = this.state.clausRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            CLAUS: {error}
          </li>
        </div>
      );
    });

    const gail_errors = this.state.gailRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            GAIL: {error}
          </li>
        </div>
      );
    });

    const tyrer_cuzick_errors = this.state.tyrerCuzickRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            Tyrer-Cuzick: {error}
          </li>
        </div>
      );
    });

    const brcapro_errors = this.state.brcaproRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            BRCAPRO: {error}
          </li>
        </div>
      );
    });

    const mmrpro_errors = this.state.mmrproRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            MMRPRO: {error}
          </li>
        </div>
      );
    });

    const pancpro_errors = this.state.pancproRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            PANCPRO: {error}
          </li>
        </div>
      );
    });

    const melapro_errors = this.state.melaproRuntimeErrors.map((error, index) => {
      return (
        <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
            MELAPRO: {error}
          </li>
        </div>
      );
    });

    const qrisk3_errors = this.state.qrisk3RuntimeErrors.map((error, index) => {
      let message = Object.values(error)
      return (
          <div key={createUUID()} className="alert alert-danger" role="alert">
          <li key={index}>
              QRISK3: {message}
          </li>
          </div>
      );
    });

    const boadicea_warnings = this.state.boadiceaRuntimeWarnings.map((error,index) => {
      return(
        <div  key={createUUID()} class="alert alert-warning" role="alert">
          BOADICEA: {error}
        </div>
        )
      })
    const brcapro_warnings = this.state.brcaproRuntimeWarnings.map((error,index) => {
      return(
          <div key={createUUID()} class="alert alert-warning" role="alert">
            BRCAPRO: {error}
          </div>
        )
      })
    const pancpro_warnings = this.state.pancproRuntimeWarnings.map((error,index) => {
      return(
          <div key={createUUID()} class="alert alert-warning" role="alert">
            PANCPRO: {error}
          </div>
        )
      })
    const mmrpro_warnings = this.state.mmrproRuntimeWarnings.map((error,index) => {
      return(
          <div key={createUUID()} class="alert alert-warning" role="alert">
            MMRPRO: {error}
          </div>
        )
      })
    const melapro_warnings = this.state.melaproRuntimeWarnings.map((error,index) => {
      return(
          <div key={createUUID()} class="alert alert-warning" role="alert">
            MELAPRO: {error}
          </div>
        )
      })

    const qrisk3_warnings = this.state.qrisk3RuntimeWarnings.map((error,index) => {
      let message = Object.values(error)
      return(
          <div key={createUUID()} class="alert alert-warning" role="alert">
              QRISK3: {message}
          </div>
        )
    })

    let risk_results = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_results : this.props.patient.risk_results;
    let risk_settings = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().risk_settings : this.props.patient.risk_settings;

    let risk_model_values = this.get5YearOr10YearRiskValues();

    let consanguineous_relationships_exist = false;

    if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
      let people = Object.values(this.props.getPedigreeData().profiles);
      for(let person of people){
        for(let relationship of person.relationship_ids){
          if(relationship.is_parent_blood_related || relationship.is_manually_connected){
            consanguineous_relationships_exist = true;
          }
        }
      }
    }

    let brca1 = "N/A";
    let brca2 = "N/A";
    let palb2 = "N/A";
    let atm = "N/A";
    let chek2 = "N/A";
    let bard1 = "N/A";
    let rad51d = "N/A";
    let rad51c = "N/A";
    let brip1 = "N/A";
    for (let z=0; z<risk_results.boadicea.mutation_probabilities.length; z++) {
      const prob = risk_results.boadicea.mutation_probabilities[z];
      if ("BRCA1" in prob) {
        brca1 = prob.BRCA1.percent;
      } else if("BRCA2" in prob) {
        brca2 = prob.BRCA2.percent;
      } else if("PALB2" in prob) {
        palb2 = prob.PALB2.percent;
      } else if("ATM" in prob) {
        atm = prob.ATM.percent;
      } else if("CHEK2" in prob) {
        chek2 = prob.CHEK2.percent;
      } else if("BARD1" in prob) {
        bard1 = prob.BARD1.percent;
      } else if("RAD51D" in prob) {
        rad51d = prob.RAD51D.percent;
      } else if("RAD51C" in prob) {
        rad51c = prob.RAD51C.percent;
      } else if("BRIP1" in prob) {
        brip1 = prob.BRIP1.percent;
      }
    }

    return (
      <React.Fragment>

        <div className="patient-fam">
          <ul className="nav nav-pills">
            <li><button id="risk_settings_link"  className="btn-link" onClick={this.openRiskSettingsModal}><i className="fa fa-cog"></i> Settings</button></li>
            <li><button id="print_risk_link" className="btn-link" onClick={this.handlePrintClick}><i className="fa fa-print"></i> Print</button></li>
            <li><button id="rerun_link" className="btn-link" onClick={() => this.onForceRerun()}><i className="fa fa-rotate-right"></i> Rerun</button></li>
          </ul>
        </div>

        <div className="risk">

          {boadicea_errors.length > 0 && (
            <ul>{boadicea_errors}</ul>
          )}

          {claus_errors.length > 0 && (
            <ul>{claus_errors}</ul>
          )}

          {gail_errors.length > 0 && (
            <ul>{gail_errors}</ul>
          )}

          {tyrer_cuzick_errors.length > 0 && (
            <ul>{tyrer_cuzick_errors}</ul>
          )}

          {brcapro_errors.length > 0 && (
            <ul>{brcapro_errors}</ul>
          )}

          {mmrpro_errors.length > 0 && (
            <ul>{mmrpro_errors}</ul>
          )}

          {pancpro_errors.length > 0 && (
            <ul>{pancpro_errors}</ul>
          )}

          {melapro_errors.length > 0 && (
            <ul>{melapro_errors}</ul>
          )}

          {qrisk3_errors.length > 0 && (
            <ul>{qrisk3_errors}</ul>
          )}

          {boadicea_warnings.length > 0 && (
            <ul>{boadicea_warnings}</ul>
          )}

          {mmrpro_warnings.length > 0 && (
            <ul>{mmrpro_warnings}</ul>
          )}

          {melapro_warnings.length > 0 && (
            <ul>{melapro_warnings}</ul>
          )}

          {pancpro_warnings.length > 0 && (
            <ul>{pancpro_warnings}</ul>
          )}

          {brcapro_warnings.length > 0 && (
            <ul>{brcapro_warnings}</ul>
          )}

          {qrisk3_warnings.length > 0 && (
            <ul>{qrisk3_warnings}</ul>
          )}

          {consanguineous_relationships_exist && (
          <ul>
            <div key={createUUID()} class="alert alert-warning" role="alert">
              Warning: Consanguineous relationships may cause risk models to be inaccurate.
            </div>
          </ul>)}

          {this.state.showRiskTables && <div className="risk-assessment-left-panel">

            {(this.state.orgLicense.boadicea || this.state.orgLicense.claus || this.state.orgLicense.gail || this.state.orgLicense.tyrer_cuzick || this.state.orgLicense.brcapro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Breast</h3>
                <table id="breast-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      {risk_model_values.column_string_year_array.map(year => (
                         <th>{year}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.boadicea && (
                    <tr key="boadicea">
                      <td className="col-gray">BOADICEA <a onClick={this.handleBoadiceaData}><i className="fa fa-external-link"></i></a></td>
                      {risk_model_values.boadicea_breast_risk_five_year_value !== null
                        ?
                          (risk_model_values.boadicea_breast_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.boadicea_breast_risk_five_year_value}%</td>
                          : <td>{risk_model_values.boadicea_breast_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.boadicea_breast_risk_ten_year_value !== null
                        ?
                          (risk_model_values.boadicea_breast_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.boadicea_breast_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.boadicea_breast_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.boadicea.breast.lifetime !== 'N/A'
                        ? <td>{risk_results.boadicea.breast.lifetime}%</td>
                        : <td>{risk_results.boadicea.breast.lifetime}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.claus && (
                    <tr key="claus">
                      <td className="col-gray">CLAUS</td>
                      {risk_model_values.claus_breast_risk_five_year_value !== null
                        ?
                          (risk_model_values.claus_breast_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.claus_breast_risk_five_year_value}%</td>
                          : <td>{risk_model_values.claus_breast_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.claus_breast_risk_ten_year_value !== null
                        ?
                          (risk_model_values.claus_breast_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.claus_breast_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.claus_breast_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.claus.breast.lifetime !== 'N/A'
                        ? <td>{risk_results.claus.breast.lifetime}%</td>
                        : <td>{risk_results.claus.breast.lifetime}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.gail && (
                    <tr key="gail">
                      <td className="col-gray">GAIL</td>
                      {risk_model_values.gail_breast_risk_five_year_value !== null
                        ?
                          (risk_model_values.gail_breast_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.gail_breast_risk_five_year_value}%</td>
                          : <td>{risk_model_values.gail_breast_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.gail_breast_risk_ten_year_value !== null
                        ?
                          (risk_model_values.gail_breast_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.gail_breast_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.gail_breast_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.gail.breast.lifetime !== 'N/A'
                        ? <td>{risk_results.gail.breast.lifetime}%</td>
                        : <td>{risk_results.gail.breast.lifetime}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.tyrer_cuzick && (
                    <tr key="tyrer_cuzick">
                      <td className="col-gray">TYRER-CUZICK</td>
                      {risk_model_values.tyrer_cuzick_breast_risk_five_year_value !== null
                        ?
                          (risk_model_values.tyrer_cuzick_breast_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.tyrer_cuzick_breast_risk_five_year_value}%</td>
                          : <td>{risk_model_values.tyrer_cuzick_breast_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.tyrer_cuzick_breast_risk_ten_year_value !== null
                        ?
                          (risk_model_values.tyrer_cuzick_breast_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.tyrer_cuzick_breast_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.tyrer_cuzick_breast_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.tyrer_cuzick.breast.lifetime !== 'N/A'
                        ? <td>{risk_results.tyrer_cuzick.breast.lifetime}%</td>
                        : <td>{risk_results.tyrer_cuzick.breast.lifetime}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.brcapro && (
                    <tr key="brcapro">
                      <td className="col-gray">BRCAPRO</td>
                      {risk_model_values.brcapro_breast_risk_five_year_value !== null
                        ?
                          (risk_model_values.brcapro_breast_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.brcapro_breast_risk_five_year_value}%</td>
                          : <td>{risk_model_values.brcapro_breast_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.brcapro_breast_risk_ten_year_value !== null
                        ?
                          (risk_model_values.brcapro_breast_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.brcapro_breast_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.brcapro_breast_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.brcapro.breast.lifetime !== 'N/A'
                        ? <td>{risk_results.brcapro.breast.lifetime}%</td>
                        : <td>{risk_results.brcapro.breast.lifetime}</td>
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.boadicea || this.state.orgLicense.brcapro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Ovarian</h3>
                <table id="ovarian-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      {risk_model_values.column_string_year_array.map(year => {
                        return <th>{year}</th>
                      })}
                      {/* <th>{risk_model_values.column_string}</th> */}
                      {/* <th>Lifetime</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.boadicea && (
                    <tr key="boadicea">
                      <td className="col-gray">BOADICEA</td>
                      {risk_model_values.boadicea_ovarian_risk_five_year_value !== null
                        ?
                          (risk_model_values.boadicea_ovarian_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.boadicea_ovarian_risk_five_year_value}%</td>
                          : <td>{risk_model_values.boadicea_ovarian_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.boadicea_ovarian_risk_ten_year_value !== null
                        ?
                          (risk_model_values.boadicea_ovarian_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.boadicea_ovarian_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.boadicea_ovarian_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.boadicea.ovarian.lifetime !== 'N/A'
                        ? <td>{risk_results.boadicea.ovarian.lifetime}%</td>
                        : <td>{risk_results.boadicea.ovarian.lifetime}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.brcapro && (
                    <tr key="brcapro">
                      <td className="col-gray">BRCAPRO</td>
                      {risk_model_values.brcapro_ovarian_risk_five_year_value !== null
                        ?
                          (risk_model_values.brcapro_ovarian_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.brcapro_ovarian_risk_five_year_value}%</td>
                          : <td>{risk_model_values.brcapro_ovarian_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.brcapro_ovarian_risk_ten_year_value !== null
                        ?
                          (risk_model_values.brcapro_ovarian_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.brcapro_ovarian_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.brcapro_ovarian_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.brcapro.ovarian.lifetime !== 'N/A'
                        ? <td>{risk_results.brcapro.ovarian.lifetime}%</td>
                        : <td>{risk_results.brcapro.ovarian.lifetime}</td>
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.mmrpro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Colon</h3>
                <table id="colon-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      {risk_model_values.column_string_year_array.map(year => {
                        return <th>{year}</th>
                      })}
                      {/* <th>{risk_model_values.column_string}</th> */}
                      {/* <th>Lifetime</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.mmrpro && (
                    <tr key="mmrpro">
                      <td className="col-gray">MMRPRO</td>
                      {risk_model_values.mmrpro_colon_risk_five_year_value !== null
                        ?
                          (risk_model_values.mmrpro_colon_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.mmrpro_colon_risk_five_year_value}%</td>
                          : <td>{risk_model_values.mmrpro_colon_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.mmrpro_colon_risk_ten_year_value !== null
                        ?
                          (risk_model_values.mmrpro_colon_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.mmrpro_colon_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.mmrpro_colon_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.mmrpro.colon.lifetime !== 'N/A'
                        ? <td>{risk_results.mmrpro.colon.lifetime}%</td>
                        : <td>{risk_results.mmrpro.colon.lifetime}</td>
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.mmrpro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Uterine/Endometrial</h3>
                <table id="uterine-endometrial-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      {risk_model_values.column_string_year_array.map(year => {
                        return <th>{year}</th>
                      })}
                      {/* <th>{risk_model_values.column_string}</th>
                      <th>Lifetime</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.mmrpro && (
                    <tr key="mmrpro">
                      <td className="col-gray">MMRPRO</td>
                      {risk_model_values.mmrpro_uterine_endometrial_risk_five_year_value !== null
                        ?
                          (risk_model_values.mmrpro_uterine_endometrial_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.mmrpro_uterine_endometrial_risk_five_year_value}%</td>
                          : <td>{risk_model_values.mmrpro_uterine_endometrial_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.mmrpro_uterine_endometrial_risk_ten_year_value !== null
                        ?
                          (risk_model_values.mmrpro_uterine_endometrial_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.mmrpro_uterine_endometrial_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.mmrpro_uterine_endometrial_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.mmrpro.uterine_endometrial.lifetime !== 'N/A'
                        ? <td>{risk_results.mmrpro.uterine_endometrial.lifetime}%</td>
                        : <td>{risk_results.mmrpro.uterine_endometrial.lifetime}</td>
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.pancpro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Pancreatic</h3>
                <table id="pancreatic-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      {risk_model_values.column_string_year_array.map(year => {
                        return <th>{year}</th>
                      })}
                      {/* <th>{risk_model_values.column_string}</th>
                      <th>Lifetime</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.pancpro && (
                    <tr key="pancpro">
                      <td className="col-gray">PANCPRO</td>
                      {risk_model_values.pancpro_pancreatic_risk_five_year_value !== null
                        ?
                          (risk_model_values.pancpro_pancreatic_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.pancpro_pancreatic_risk_five_year_value}%</td>
                          : <td>{risk_model_values.pancpro_pancreatic_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.pancpro_pancreatic_risk_ten_year_value !== null
                        ?
                          (risk_model_values.pancpro_pancreatic_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.pancpro_pancreatic_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.pancpro_pancreatic_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.pancpro.pancreatic.lifetime !== 'N/A'
                        ? <td>{risk_results.pancpro.pancreatic.lifetime}%</td>
                        : <td>{risk_results.pancpro.pancreatic.lifetime}</td>
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.melapro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Melanoma</h3>
                <table id="melanoma-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      {risk_model_values.column_string_year_array.map(year => {
                        return <th>{year}</th>
                      })}
                      {/* <th>{risk_model_values.column_string}</th>
                      <th>Lifetime</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.melapro && (
                    <tr key="melapro">
                      <td className="col-gray">MELAPRO</td>
                      {risk_model_values.melapro_melanoma_risk_five_year_value !== null
                        ?
                          (risk_model_values.melapro_melanoma_risk_five_year_value !== 'N/A')
                          ? <td>{risk_model_values.melapro_melanoma_risk_five_year_value}%</td>
                          : <td>{risk_model_values.melapro_melanoma_risk_five_year_value}</td>
                        : null
                      }
                      {risk_model_values.melapro_melanoma_risk_ten_year_value !== null
                        ?
                          (risk_model_values.melapro_melanoma_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.melapro_melanoma_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.melapro_melanoma_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_results.melapro.melanoma.lifetime !== 'N/A'
                        ? <td>{risk_results.melapro.melanoma.lifetime}%</td>
                        : <td>{risk_results.melapro.melanoma.lifetime}</td>
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.qrisk3) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Cardiovascular</h3>
                <table id="cardiovascular-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      <th>10 Year</th>
                      <th>Average</th>
                      <th>Relative Risk</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.qrisk3 && (
                    <tr key="qrisk3">
                      <td className="col-gray">QRISK3</td>
                      {risk_model_values.qrisk3_cardiovascular_risk_ten_year_value !== null
                        ?
                          (risk_model_values.qrisk3_cardiovascular_risk_ten_year_value !== 'N/A')
                          ? <td>{risk_model_values.qrisk3_cardiovascular_risk_ten_year_value}%</td>
                          : <td>{risk_model_values.qrisk3_cardiovascular_risk_ten_year_value}</td>
                        : null
                      }
                      {risk_model_values.qrisk3_cardiovascular_ !== null
                      ?
                        (risk_model_values.qrisk3_cardiovascular_risk_average_value !== 'N/A')
                        ? <td>{risk_model_values.qrisk3_cardiovascular_risk_average_value}%</td>
                        : <td>{risk_model_values.qrisk3_cardiovascular_risk_average_value}</td>
                      : null
                      }
                      {risk_model_values.qrisk3_cardiovascular_risk_relative_risk_value !== null
                        ?
                          (risk_model_values.qrisk3_cardiovascular_risk_relative_value !== 'N/A')
                          ? <td>{risk_model_values.qrisk3_cardiovascular_risk_relative_value}</td>
                          : <td>{risk_model_values.qrisk3_cardiovascular_risk_relative_value}</td>
                        : null
                      }
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            )}

            {(this.state.orgLicense.boadicea || this.state.orgLicense.tyrer_cuzick || this.state.orgLicense.brcapro
              || this.state.orgLicense.mmrpro || this.state.orgLicense.pancpro || this.state.orgLicense.melapro) && (
            <div className="risk-table">
              <div className="risk-table--group">
                <h3 className="margin-two-bottom">Mutation</h3>
                {(this.state.orgLicense.boadicea || this.state.orgLicense.tyrer_cuzick || this.state.orgLicense.brcapro) && (
                <table id="mutation-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      <th>BRCA1</th>
                      <th>BRCA2</th>
                      <th>PALB2</th>
                      <th>ATM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.boadicea && (
                    <tr key="boadicea">
                      <td className="col-gray">BOADICEA</td>
                      {brca1 !== 'N/A'
                        ? <td>{brca1}%</td>
                        : <td>{brca1}</td>
                      }
                      {brca2 !== 'N/A'
                        ? <td>{brca2}%</td>
                        : <td>{brca2}</td>
                      }
                      {palb2 !== 'N/A'
                        ? <td>{palb2}%</td>
                        : <td>{palb2}</td>
                      }
                      {atm !== 'N/A'
                        ? <td>{atm}%</td>
                        : <td>{atm}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.tyrer_cuzick && (
                    <tr key="tyrer_cuzick">
                      <td className="col-gray">TYRER-CUZICK</td>
                      {risk_results.tyrer_cuzick.mutation_probabilities[1].BRCA1.percent !== 'N/A'
                        ? <td>{risk_results.tyrer_cuzick.mutation_probabilities[1].BRCA1.percent}%</td>
                        : <td>{risk_results.tyrer_cuzick.mutation_probabilities[1].BRCA1.percent}</td>
                      }
                      {risk_results.tyrer_cuzick.mutation_probabilities[2].BRCA2.percent !== 'N/A'
                        ? <td>{risk_results.tyrer_cuzick.mutation_probabilities[2].BRCA2.percent}%</td>
                        : <td>{risk_results.tyrer_cuzick.mutation_probabilities[2].BRCA2.percent}</td>
                      }
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                    </tr>
                    )}
                    {this.state.orgLicense.brcapro && (
                    <tr key="brcapro">
                      <td className="col-gray">BRCAPRO</td>
                      {risk_results.brcapro.mutation_probabilities[1].BRCA1.percent !== 'N/A'
                        ? <td>{risk_results.brcapro.mutation_probabilities[1].BRCA1.percent}%</td>
                        : <td>{risk_results.brcapro.mutation_probabilities[1].BRCA1.percent}</td>
                      }
                      {risk_results.brcapro.mutation_probabilities[2].BRCA2.percent !== 'N/A'
                        ? <td>{risk_results.brcapro.mutation_probabilities[2].BRCA2.percent}%</td>
                        : <td>{risk_results.brcapro.mutation_probabilities[2].BRCA2.percent}</td>
                      }
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                    </tr>
                    )}
                  </tbody>
                </table>
                )}

                {(this.state.orgLicense.boadicea) && (
                <table id="mutation-risk-table" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      <th>CHEK2</th>
                      <th>BARD1</th>
                      <th>RAD51D</th>
                      <th>RAD51C</th>
                      <th>BRIP1</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="boadicea">
                      <td className="col-gray">BOADICEA</td>
                      {chek2 !== 'N/A'
                        ? <td>{chek2}%</td>
                        : <td>{chek2}</td>
                      }
                      {bard1 !== 'N/A'
                        ? <td>{bard1}%</td>
                        : <td>{bard1}</td>
                      }
                      {rad51d !== 'N/A'
                        ? <td>{rad51d}%</td>
                        : <td>{rad51d}</td>
                      }
                      {rad51c !== 'N/A'
                        ? <td>{rad51c}%</td>
                        : <td>{rad51c}</td>
                      }
                      {brip1 !== 'N/A'
                        ? <td>{brip1}%</td>
                        : <td>{brip1}</td>
                      }
                    </tr>
                  </tbody>
                </table>
                )}

                {(this.state.orgLicense.mmrpro ||  this.state.orgLicense.pancpro || this.state.orgLicense.melapro) && (
                <table id="mutation-risk-table-2" className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="col-gray--head">Model</th>
                      <th>P16</th>
                      <th>MLH1</th>
                      <th>MSH2</th>
                      <th>MSH6</th>
                      <th>PANCREATIC GENE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orgLicense.mmrpro && (
                    <tr key="mmrpro">
                      <td className="col-gray">MMRPRO</td>
                      <td>{"N/A"}</td>
                      {risk_results.mmrpro.mutation_probabilities[1].MLH1.percent !== 'N/A'
                        ? <td>{risk_results.mmrpro.mutation_probabilities[1].MLH1.percent}%</td>
                        : <td>{risk_results.mmrpro.mutation_probabilities[1].MLH1.percent}</td>
                      }
                      {risk_results.mmrpro.mutation_probabilities[2].MSH2.percent !== 'N/A'
                        ? <td>{risk_results.mmrpro.mutation_probabilities[2].MSH2.percent}%</td>
                        : <td>{risk_results.mmrpro.mutation_probabilities[2].MSH2.percent}</td>
                      }
                      {risk_results.mmrpro.mutation_probabilities[3].MSH6.percent !== 'N/A'
                        ? <td>{risk_results.mmrpro.mutation_probabilities[3].MSH6.percent}%</td>
                        : <td>{risk_results.mmrpro.mutation_probabilities[3].MSH6.percent}</td>
                      }
                      <td>{"N/A"}</td>
                    </tr>
                    )}
                    {this.state.orgLicense.pancpro && (
                    <tr key="pancpro">
                      <td className="col-gray">PANCPRO</td>
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                      {risk_results.pancpro.mutation_probabilities[1].pancreaticGene.percent !== 'N/A'
                        ? <td>{risk_results.pancpro.mutation_probabilities[1].pancreaticGene.percent}%</td>
                        : <td>{risk_results.pancpro.mutation_probabilities[1].pancreaticGene.percent}</td>
                      }
                    </tr>
                    )}
                    {this.state.orgLicense.melapro && (
                    <tr key="melapro">
                      <td className="col-gray">MELAPRO</td>
                      {risk_results.melapro.mutation_probabilities[1].P16.percent !== 'N/A'
                        ? <td>{risk_results.melapro.mutation_probabilities[1].P16.percent}%</td>
                        : <td>{risk_results.melapro.mutation_probabilities[1].P16.percent}</td>
                      }
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                      <td>{"N/A"}</td>
                    </tr>
                    )}
                  </tbody>
                </table>
                )}
              </div>
            </div>
            )}
          </div>}

          {(this.state.orgLicense.boadicea || this.state.orgLicense.brcapro || this.state.orgLicense.claus || this.state.orgLicense.gail
          || this.state.orgLicense.melapro || this.state.orgLicense.mmrpro || this.state.orgLicense.pancpro || this.state.orgLicense.tyrer_cuzick || this.state.orgLicense.qrisk3) && (
          <div className="risk-assessment-right-panel">
            {this.state.showRiskAssessmentCharts && <RiskAssessmentCharts boadiceaCancerRisks={risk_results.boadicea.cancer_risks}
              niceRiskResults={risk_results.boadicea.nice_risk_results}
              boadiceaPopulationRisks={risk_results.boadicea.population_cancer_risks}
              clausCancerRisks={risk_results.claus.cancer_risks}
              gailCancerRisks={risk_results.gail.cancer_risks}
              tyrerCuzickCancerRisks={risk_results.tyrer_cuzick.cancer_risks}
              brcaproCancerRisks={risk_results.brcapro.cancer_risks}
              mmrproCancerRisks={risk_results.mmrpro.cancer_risks}
              pancproCancerRisks={risk_results.pancpro.cancer_risks}
              melaproCancerRisks={risk_results.melapro.cancer_risks}
              qrisk3CancerRisks={risk_results.qrisk3}
              orgLicense={this.state.orgLicense}
              ref={this.riskAssessmentChartsRef}
            />}
          </div>
          )}

        </div>

        {!this.state.orgLicense.boadicea && !this.state.orgLicense.brcapro && !this.state.orgLicense.claus && !this.state.orgLicense.gail
          && !this.state.orgLicense.melapro && !this.state.orgLicense.mmrpro && !this.state.orgLicense.pancpro && !this.state.orgLicense.qrisk3
          && !this.state.orgLicense.tyrer_cuzick && (
          <div style={{textAlign: 'center'}}>
            <p>Your organization does not currently have any risk models licensed</p>
          </div>
        )}

        {this.state.showMissingDataModal &&
          <ModalRiskMissingData
            requiredData={this.state.risk_errors.requiredData}
            fatalTerminations={this.state.risk_errors.fatalTerminations}
            pedigreeData={this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData() : this.props.patient}
            onClose={this.closeMissingDataModal}
            onClickSave={(payload) => this.onSaveMissingData(payload)}
          />
        }

        {this.state.showRiskSettingsModal &&
          <ModalRiskSettings
            onClose={this.closeRiskSettingsModal}
            onClickSave={(payload) => this.onSaveRiskSettings(payload)}
            riskSettings={risk_settings}
            session={this.props.session}
          />
        }

        <ActivityIndicator
          pedigree={true}
          loading={this.state.runningRiskCalculation}
          stopAction={() => this.stopRiskCalculation()}
          riskAssessmentIndicator={true}
        />

        {this.state.showNoRiskResultsModal && (
          <ModalPopUpBlockerAlert
            loading={false}
            errorMessages={[]}
            title="Print"
            message="Currently, there are no risk model results to print."
            isOpen={this.state.showNoRiskResultsModal}
            onOk={() => this.closeNoRiskResultsModal()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}
      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  patient: state.patient,
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(RiskAssessmentTabs);
