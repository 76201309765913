import React, { useState } from 'react';
import Select from 'react-select';
//@ts-ignore
import {Checkbox} from 'react-icheck';

interface printCheckBoxProps {
  disabled?: boolean;
  identify: boolean;
  blackWhite: boolean;
  updateBlackWhite: (value: boolean) => void;
  updateDeIdentify: (value: boolean) => void;
}

const PrintCheckBoxes: React.FC<printCheckBoxProps> = ({ updateDeIdentify, updateBlackWhite, identify, blackWhite, disabled }) => {
  return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <h5 style={{ width: '100px' }}>Options</h5>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Checkbox
                            id="Black/White Icons"
                            name="Icons"
                            onChange={(e:any, checked:boolean) => {
                                updateBlackWhite(checked)
                              }}
                            checkboxClass="icheckbox_flat-green"
                            increaseArea="20%"
                            checked={blackWhite}
                        />
                        <label htmlFor="Black/White Icons" className='print-labels' >Black/White Icons</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Checkbox
                            id="deIdentify"
                            name="deIdentify"
                              onChange={(e:any, checked:boolean) => {
                                updateDeIdentify(checked)
                              }}                            
                            checkboxClass="icheckbox_flat-green"
                            increaseArea="20%"
                            checked={identify}
                        />
                        <label htmlFor="deIdentify" className='print-labels'>De-identified</label>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PrintCheckBoxes;
